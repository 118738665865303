import { Alert, Confirmation } from "@cbmisorg/material";
import * as appVariables from "../../../../../../configuration/appVariables";
import * as appFunctions from "../../../../../../util/appFunctions";
import { appLangDirection, dictionary } from "../../../../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../../../../graphql/schema/GenerateQuery";
import { alertStyle } from "../../../../../../util/appStyle";
const tblActivityGuest = generateQueries("tblActivityGuest");

const strPageInfo = "@src/client/component/shared/account/components/regActivityGuestStudent/regActivityGuestStudent.controller.js";
const labels = dictionary?.components?.stakeholders?.member?.account?.runningActivity;

export default function regActivityGuestStudentCtrl({ appState, appDispatch, isLoading, setIsLoading }) {
  const lang = appState?.clientInfo?.strLanguage;
  const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

  const handler = {
    initData:
      ({ state, setState, fields }) =>
      async () => {
        try {
          fields.controller.resetForm();

          // const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

          let lstMajorOption = [];
          let lstAcademicYearOption = [];
          let lstGenderOption = [];
          let lstUniversityOption = [];

          Object.entries(objCat?.Root?.country?.kuwait?.university || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstUniversityOption.push({ key: value?.id, value: value?.value });
            }
          });
          lstUniversityOption.push({ key: "other", value: dictionary?.shared?.prefix?.otherBtn });

          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.academicYear || {}).forEach(([key, value], index) => {
            if (key !== "id" && key !== "value" && index < 6) {
              lstAcademicYearOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.value });
            }
          });

          fields.strUniversityName.setOptions([...lstUniversityOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields.bigMajorID.setOptions([...lstMajorOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields.bigGender.setOptions(lstGenderOption);
          fields.bigAcademicYearID.setOptions(lstAcademicYearOption);

          state.blnIsNew = true;
          if (appState?.userInfo?.jsnUserInfo?.strFullName) {
            fields.strFullName.setValue(appState?.userInfo?.jsnUserInfo?.strFullName?.[lang] || appState?.userInfo?.jsnUserInfo?.strFullName);
          }
          if (appState?.userInfo?.jsnUserInfo?.strMajor) {
            fields.bigMajorID.setValue(appState?.userInfo?.jsnUserInfo?.strMajor);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigGender) {
            fields.bigGender.setValue(appState?.userInfo?.jsnUserInfo?.bigGender);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigAcademicYearID) {
            fields.bigAcademicYearID.setValue(appState?.userInfo?.jsnUserInfo?.bigAcademicYearID);
          }
          if (appState?.userInfo?.jsnUserInfo?.strUniversityName) {
            fields.strUniversityName.setValue(appState?.userInfo?.jsnUserInfo?.strUniversityName);
          }
          if (appState?.userInfo?.jsnUserInfo?.strGPA) {
            fields.strGPA.setValue(appState?.userInfo?.jsnUserInfo?.strGPA);
          }
          if (appState?.userInfo?.jsnUserInfo?.strGPAof) {
            fields.strGPAof.setValue(appState?.userInfo?.jsnUserInfo?.strGPAof);
          } else {
            fields.strGPAof.setValue("100");
          }
          handler?.handelGPAtypeChange({ fields })();
          state.lstFirstProofSection = Array.isArray(appState?.userInfo?.jsnUserInfo?.lstFirstProofSection) ? appState?.userInfo?.jsnUserInfo?.lstFirstProofSection : [];
          setState({ ...state });
        } catch (error) {
          appFunctions.logError(strPageInfo, "openModal-ERROR", error);
        }
      },
    addGuest:
      ({ fields, setState, state, closeModal, activityInfo, uploaderFirstProofSection, parentState, setParentState }) =>
      async () => {
        try {
          // console.log(parentState);

          // return
          //#region Form Valid
          if (!fields?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }
          if (!state?.lstFirstProofSection?.length) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.studentUploadFile?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }

          //#endregion
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }
          setIsLoading(true);
          if (state?.lstFirstProofSection?.length) {
            const resultUploadReceipt = await uploaderFirstProofSection?.uploadHandler("lstFirstProofSection");
            if (!resultUploadReceipt) {
              setIsLoading(false);
              return;
            }
          }
          const activityIndex = parentState?.lstUserRegActivity?.findIndex((item) => String(item?.bigActivityType) === String(activityInfo?.objActivity?.id));
          let objInfo = {};
          if (activityIndex > -1) {
            objInfo = parentState?.lstUserRegActivity?.[activityIndex];
          }

          let lstSubjectStuden = [];
          const lstOrgSubjectStuden = [];
          for (let index = 0; index < objInfo?.jsnGuestInfo?.objSubject.length; index++) {
            const element = objInfo?.jsnGuestInfo?.objSubject?.[index];
            lstOrgSubjectStuden?.push(Number(element));
          }
          for (let index = 0; index < activityInfo?.lstActiveSubjectOrdered.length; index++) {
            const element = activityInfo?.lstActiveSubjectOrdered[index];
            if (String(element?.subjectValue?.defaultCatTargeted?.value?.eng)?.includes(String(appVariables.workSectorCat?.student))) {
              lstSubjectStuden.push(element?.subjectValue?.id);
            }
          }
          lstSubjectStuden = appFunctions.uniq_fast([...lstOrgSubjectStuden, ...lstSubjectStuden]);

          // #endregion
          const result = await tblActivityGuest(strPageInfo, appState, "aaiotActivityGuestAdd", {
            objectInput: {
              //#region tblUser Information
              bigAppID: appVariables.App_ID,
              bigSystemID: appVariables.aaiot_System_ID,
              bigUserID: appState?.userInfo?.bigUserID,
              strUserEmail: appState?.userInfo?.strUserEmail,
              bigWorkSector: objInfo?.bigWorkSector || appState?.userInfo?.jsnUserInfo?.bigWorkSector || appVariables?.workSectorID?.prvt,

              jsnUserInfo: {
                ...appState?.userInfo?.jsnUserInfo,
                strFullName: fields?.strFullName?.getValue(),
                strMajor: fields?.bigMajorID?.getValue()?.key,
                bigGender: fields?.bigGender?.getValue()?.key,
                bigAcademicYearID: fields?.bigAcademicYearID?.getValue()?.key,
                strUniversityName: fields?.strUniversityName?.getValue()?.key,
                strGPA: fields.strGPA?.getValue(),
                strGPAof: fields.strGPAof?.getValue()?.key,
                lstFirstProofSection: state?.lstFirstProofSection,
                blnIsFirstSections: true,
              },

              blnIsUserLoggedIn: appState?.clientInfo?.blnIsUserLoggedIn,
              blnIsActivityUser: true,
              //#endregion
              ///////////////////////////////////////////////////
              //#region tblActivityGuest information
              bigActivityType: activityInfo?.objActivity?.id,
              jsnGuestInfo: {
                strFullName: fields?.strFullName?.getValue() || appState?.userInfo?.jsnUserInfo?.strFullName,
                objSubject: lstSubjectStuden,
                objTargted: objInfo?.jsnGuestInfo?.objTargted ? objInfo?.jsnGuestInfo?.objTargted : appVariables?.workSectorCat?.student,
                blnIsFirstSections: true,
                bigAcademicYearID: fields?.bigAcademicYearID?.getValue()?.key,
                strAcademicYearID: fields?.bigAcademicYearID?.getValue()?.value,
                bigUniversityID: fields?.strUniversityName?.getValue()?.key,
                strUniversityName: fields?.strUniversityName?.getValue()?.value,

                strGPA: fields.strGPA?.getValue(),
                strGPAof: fields.strGPAof?.getValue()?.key,
                lstFirstProofSection: state?.lstFirstProofSection,
              },
              //#endregion
              ///////////////////////////////////////////////////
              //#region Statistic ??
              // lstStatisticFelidIfGuestActivityNew: ["intCountActivityGuest", "intCountActivityGuestAll"],
              //#endregion
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            } else {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            }
            setIsLoading(false);
            return;
          }

          if (!Array.isArray(parentState.lstUserRegActivity)) {
            parentState.lstUserRegActivity = [];
          }
          if (!parentState.lstUserRegActivity?.length) {
            parentState.lstUserRegActivity.push(result?.response?.guestInfo);
          } else {
            for (let i = 0; i < parentState.lstUserRegActivity.length; i++) {
              if (String(parentState.lstUserRegActivity?.[i]?.bigActivityType) === String(activityInfo?.objActivity?.id)) {
                parentState.lstUserRegActivity[i] = { ...parentState.lstUserRegActivity?.[i], ...result?.response?.guestInfo };
              }
            }
          }
          setParentState({ ...parentState });
          setIsLoading(false);
          setState({ ...state });
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_innfo_Successfully?.[lang], "success", appLangDirection?.[lang], alertStyle);
          fields?.controller?.resetForm();
          appState.clientInfo.blnIsUserLoggedIn = true;
          appState.userInfo = result.response?.userInfo;
          appDispatch();
          closeModal();
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "addGuest-ERROR", error);
          setIsLoading(false);
        }
      },
    ConfirmInvitationGuest:
      ({ activityInfo, parentState, setParentState, closeModal }) =>
      async () => {
        try {
          //#endregion
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }
          setIsLoading(true);

          const activityIndex = parentState?.lstUserRegActivity?.findIndex((item) => String(item?.bigActivityType) === String(activityInfo?.objActivity?.id));
          let objInfo = {};
          if (activityIndex > -1) {
            objInfo = parentState?.lstUserRegActivity?.[activityIndex];
          }

          // #endregion
          objInfo.jsnGuestInfo.blnIsConfirmInvitation=true;
          const result = await tblActivityGuest(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: {
              jsnGuestInfo: {
                ...objInfo?.jsnGuestInfo,
              },
              dtmUpdatedDate: new Date()?.toISOString()
              //#endregion
              ///////////////////////////////////////////////////
            },
            objectCondition:{
              bigActivityType: activityInfo?.objActivity?.id,
              bigActivityGuestID: objInfo?.bigActivityGuestID,
            }
          });

          if (!result?.blnIsRequestSuccessful) {
            if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            } else {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            }
            setIsLoading(false);
            return;
          }

          if (!Array.isArray(parentState.lstUserRegActivity)) {
            parentState.lstUserRegActivity = [];
          }
          if (!parentState.lstUserRegActivity?.length) {
            parentState.lstUserRegActivity.push(result?.response?.guestInfo);
          } else {
            for (let i = 0; i < parentState.lstUserRegActivity.length; i++) {
              if (String(parentState.lstUserRegActivity?.[i]?.bigActivityType) === String(activityInfo?.objActivity?.id)) {
                parentState.lstUserRegActivity[i] = { ...parentState.lstUserRegActivity?.[i], ...result?.response?.guestInfo };
              }
            }
          }
          
          setParentState({ ...parentState });
          setIsLoading(false);

          if (typeof closeModal =="function") {
            closeModal()();
          }
        
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_innfo_Successfully?.[lang], "success", appLangDirection?.[lang], alertStyle);
          // appState.clientInfo.blnIsUserLoggedIn = true;
          // appState.userInfo = result.response?.userInfo;
          // appDispatch();
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "addGuest-ERROR", error);
          setIsLoading(false);
        }
      },
    handelGPAtypeChange:
      ({ fields }) =>
      (event) => {
        try {
          const typeValue = fields.strGPAof?.getValue()?.key;
          if (String(typeValue) === "100") {
            fields?.strGPA?.setValidation("required", true);
            fields?.strGPA?.setValidation("onlyNumbers", true);
            fields?.strGPA?.setValidation("maxNumber", 100);
            if (isNaN(fields?.strGPA?.getValue())) {
              fields?.strGPA?.setError(dictionary?.formapi?.strGPA?.validationsMsg?.onlyNumber);
            }
          } else if (String(typeValue) === "4") {
            fields?.strGPA?.setValidation("required", true);
            fields?.strGPA?.setValidation("onlyNumbers", true);
            fields?.strGPA?.setValidation("maxNumber", 4);
            if (isNaN(fields?.strGPA?.getValue())) {
              fields?.strGPA?.setError(dictionary?.formapi?.strGPA?.validationsMsg?.onlyNumber);
            } else if (Number(fields?.strGPA?.getValue()) > 4) {
              fields?.strGPA?.setError(dictionary?.formapi?.strGPA?.validationsMsg?.maxNumber);
            }
          } else if (String(typeValue) === "A") {
            fields?.strGPA?.setValidation("required", {}, { required: true });
          }
        } catch (error) {
          appFunctions.logError(strPageInfo, "handelChangeEmployment-ERROR", error);
        }
      },

    objCat: objCat,
  };
  return handler;
}
