import React, { useEffect, useState } from "react";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { Button, Grid, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/material";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import traineeSetterModalCtrl from "./traineeSetterModal.controller";
import { App_info_Color } from "../../../../../../util/appStyle";
import Uploader from "../../../../../../../old/components/sharedUi/uploader/Uploader";
import { countryCodeTrainingTypeObj, objUploadType, workSectorID } from "../../../../../../configuration/appVariables";
import appRouting from "../../../../../../configuration/routing/appRouting";
import { generatePath } from "react-router-dom";
import { Icon } from "@cbmisorg/icons";
import WorkSectorOption from "../regActivityGuest/component/WorkSectorOption.view";
import { modalOverFllow } from "../../../../../../util/appFunctions";

const labels = dictionary?.components?.stakeholders?.member?.account?.traineeSetter;

export default function TraineeSetterModalView({ parentState, isLoading, setIsLoading, blnIsStartUp = false, classes }) {
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const [state, setState] = useState({ blnOpenSetterModal: false, blnIsNew: true, lstFirstProofSection: [], blnHoverd: false });
  const uploaderFirstProofSection = Uploader(state, setState, "lstFirstProofSection", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: dictionary?.components?.uploader?.receipt?.[lang],
    blnHoverd: false,
  });
  const fields = FormData({
    bigMajorID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigDegreeID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigGender: { control: "list", validations: { required: true }, blnLanguage: false },
    bigEmploymentID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigCountryOfCitizenID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigCountryOfResidenceID: { control: "list", blnLanguage: false }, //disabled

    // student
    strUniversityName: { control: "list", validations: {}, blnLanguage: false },
    // bigAcademicYearID: { control: "list", validations: {}, blnLanguage: false }, //disabled
    // bigGpaID: { control: "list", validations: {}, blnLanguage: false }, //disabled
    // blnIsFirstSections: { control: "switch", blnLanguage: false }, //disabled

    // // manager
    // strPlaceName: { control: "text", validations: {}, blnLanguage: false }, //disabled
    // strPositionTitle: { control: "text", validations: {}, blnLanguage: false }, //disabled

    // blnExperience: { control: "text", validations: {}, blnLanguage: false }, //disabled
    // intExperienceYear: { control: "text", validations: {}, blnLanguage: false }, //disabled

    bigWorkSector: {
      control: "list",
      blnLanguage: false,
      validations: {},
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },
  });

  const handlers = traineeSetterModalCtrl({ appDispatch, appState, isLoading, setIsLoading, blnIsStartUp });
  const openModal = handlers.openModal({ fields, setState, state });
  const updateTraineeInfo = handlers.updateTraineeInfo({ fields, setState, state, uploaderFirstProofSection });
  const onChangeCountry = handlers.onChangeCountry({ fields});
  const handelChangeEmployment = handlers.handelChangeEmployment({ fields});

  const goPath = () => {
    let path = generatePath(appRouting?.Public_Initiative?.url, { strTrainingType: countryCodeTrainingTypeObj?.[appState?.userInfo?.jsnUserInfo?.strTrainingTypeCode] || "fullstack" });
    if(appState?.userInfo?.strTrainingTypeCode && appState?.userInfo?.strTrainingTypeCode !== appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.strDefaultContryRegisterCode){
      path =path + "-" + appState?.userInfo?.strTrainingTypeCode
    } 
    window.open(path, "_blank");
  };

  useEffect(() => {
    return () => {
      modalOverFllow()
    }

  }, [])
  
  return {
    openEle: (
      <Paper className={classes?.bgBox}>
        <Grid container justifyContent="center">
          <Grid item>
            <Typography as="subtitle2" className={classes?.mainTitle}>
              {labels?.title?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs="12" md="12" className={classes?.descriptionLightTextContainer} key="trainingContainer">
            <Typography
              as="body2"
              className={`${!parentState?.objPartnerInfo?.jsnSupportTrans?.length ? classes?.descriptionLightText : classes?.descriptionLightText5} ${
                state?.blnHoverd ? classes?.descriptionLightTextActive : ""
              }`}
              key="training"
              onMouseEnter={() => {
                setState({ ...state, blnHoverd: true });
              }}
              onMouseLeave={() => {
                setState({ ...state, blnHoverd: false });
              }}
            >
              {labels?.description?.[lang]}
            </Typography>
            <Grid container justifyContent="center" className={`${classes?.arrowClose} arrowClose`} key="arrowClose_training">
              <Grid item xs="auto" md="0" my-2 key="arrowClose_training_item">
                <Icon icon="KeyboardArrowUp" size="30px" color={App_info_Color} onClick={() => {}} key="arrowClose_icon" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              label={labels?.btnDetails?.[lang]}
              mode="outlined"
              color={App_info_Color}
              onClick={goPath}
              className={classes?.btnBox}
            />
          </Grid>
          <Grid item>
            <Button
              label={appState?.userInfo?.blnIsStudent ? dictionary?.shared?.buttons?.editUpd?.[lang] : labels?.btn?.[lang]}
              mode="outlined"
              color={App_info_Color}
              onClick={openModal}
              className={classes?.btnBox}
            />
          </Grid>
        </Grid>
      </Paper>
    ),

    modal: (
      <Modal
        open={state.blnOpenSetterModal}
        eventClose={() => {
          modalOverFllow();
          setState({ ...state, blnOpenSetterModal: false });
        }}
        className={classes?.modal}
      >
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={App_info_Color} className={classes?.labelModal}>
            {labels?.title?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12} sm="12">
              <FormField
                objHandler={fields.bigCountryOfCitizenID}
                icon="flag"
                mode={"classic"}
                
                color={App_info_Color}
                className={classes?.field}
                onChange={onChangeCountry}
              />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigGender} icon="Wc" mode={"classic"}  color={App_info_Color} className={classes?.field} />
            </Grid>
            {/*   <Grid item xs={12} sm="6">
              <FormField
                objHandler={fields.bigCountryOfResidenceID}
                onChange={onChangeCountry}
                icon="LocationOn"
                mode={"classic"}
                
                color={App_info_Color}
                className={classes?.field}
              />
            </Grid> */}
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigEmploymentID} icon="work" mode={"classic"}  color={App_info_Color} className={classes?.field} onChange={handelChangeEmployment}/>
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField
                objHandler={fields.bigDegreeID}
                icon="documentScannerRounded"
                mode={"classic"}
                
                color={App_info_Color}
                className={classes?.field}
              />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigMajorID} icon="book" mode={"classic"}  color={App_info_Color} className={classes?.field} />
            </Grid>
            {handlers?.objCat?.Root?.employment?.student?.id === fields.bigEmploymentID?.getValue()?.key ? (
              <React.Fragment>
                <Grid item xs={12} sm="12">
                  <FormField
                    objHandler={fields.strUniversityName}
                    icon="accountBalance"
                    mode={"classic"}
                    
                    color={App_info_Color}
                    className={classes?.field}
                  />
                </Grid>
                {/*  <Grid item xs={12} sm="6">
                  <FormField
                    objHandler={fields.bigAcademicYearID}
                    icon="AutoStories"
                    mode={"classic"}
                    
                    color={App_info_Color}
                    className={classes?.field}
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm="12">
                  <FormField
                    objHandler={fields.bigGpaID}
                    icon="insertChartOutlined"
                    mode={"classic"}
                    
                    color={App_info_Color}
                    className={classes?.field}
                  />
                </Grid> */}
                {/*  <Grid item xs={12} sm="12">
                  <FormField objHandler={fields.blnIsFirstSections}  color={App_info_Color} className={classes?.field} />
                </Grid> */}
                {/*  {!fields?.blnIsFirstSections?.getValue() ? null : (
                  <Grid item xs="12" container sx={{ sm: { justifyContent: "center" } }}>
                    <Grid item xs="12" md="auto">
                      <Typography as="body2" className={classes?.descriptionLightText}>
                        {labels?.proofUpload?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item>{uploaderFirstProofSection?.JSX}</Grid>
                  </Grid>
                )} */}
              </React.Fragment>
            ) : handlers?.objCat?.Root?.employment?.unemployed?.id === fields.bigEmploymentID?.getValue()?.key ? (
              <React.Fragment>
                {/*    <Grid item xs={12} sm="12">
                  <FormField
                    objHandler={fields.strUniversityName}
                    icon="accountBalance"
                    mode={"classic"}
                    
                    color={App_info_Color}
                    className={classes?.field}
                  />
                </Grid> */}
                {/*  <Grid item xs={12} sm="6">
                  <FormField
                    objHandler={fields.bigGpaID}
                    icon="insertChartOutlined"
                    mode={"classic"}
                    
                    color={App_info_Color}
                    className={classes?.field}
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm="12">
                  <FormField
                    objHandler={fields.blnExperience}
                    
                    color={App_info_Color}
                    className={classes?.field}
                    defaultChecked={appState?.userInfo?.jsnUserInfo?.blnExperience}
                  />
                </Grid> */}
                {/*  {!fields?.blnExperience?.getValue() ? null : (
                  <Grid item xs={12} sm="12">
                    <FormField
                      objHandler={fields.intExperienceYear}
                      icon="HourglassBottom"
                      mode={"classic"}
                      
                      color={App_info_Color}
                      className={classes?.field}
                    />
                  </Grid>
                )} */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid item xs={12} sm="12">
                  <FormField objHandler={fields.bigWorkSector} icon="Work" mode={"classic"}  color={App_info_Color} className={classes?.field} />
                </Grid>

                {/*  <Grid item xs={12} sm="6">
                  <FormField
                    objHandler={fields.strPlaceName}
                    icon="accountBalance"
                    mode={"classic"}
                    
                    color={App_info_Color}
                    className={classes?.field}
                  />
                </Grid> */}
                {/*  <Grid item xs={12} sm="6">
                  <FormField
                    objHandler={fields.strPositionTitle}
                    icon="work"
                    mode={"classic"}
                    
                    color={App_info_Color}
                    className={classes?.field}
                  />
                </Grid> */}
                {/*  <Grid item xs={12} sm="12">
                  <FormField
                    objHandler={fields.intExperienceYear}
                    icon="HourglassBottom"
                    mode={"classic"}
                    
                    color={App_info_Color}
                    className={classes?.field}
                  />
                </Grid> */}
              </React.Fragment>
            )}
          </Grid>

          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item xs="12" pt-8 pb-5>
              <Button
                py-0
                px-10
                label={dictionary?.shared?.buttons?.submitBtn?.[lang]}
                color={App_info_Color}
                onClick={updateTraineeInfo}
                mode="outlined"
                className={classes?.btnBox}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),
  };
}
