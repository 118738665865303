import { Button, Grid, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/material";
import React, { useState } from "react";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import { App_info_Color, App_Light_Text_Color } from "../../../../../../util/appStyle";
import robot7 from "../../../../../../util/assets/images/robot7.png";
import robot8 from "../../../../../../util/assets/images/robot8.png";
import lineImage from "../../../../../../util/assets/images/Mediamodifier-Design.svg";

import RegActivityGuestView from "../regActivityGuest/RegActivityGuest.view";
import RegActivityGuestStudentView from "../regActivityGuestStudent/RegActivityGuestStudent.view";
import { modalOverFllow } from "../../../../../../util/appFunctions";
import { workSectorID } from "../../../../../../configuration/appVariables";
import regActivityGuestStudentCtrl from "../regActivityGuestStudent/regActivityGuestStudent.controller";
import { App_Gray_Color } from "../../../../../../../old/appHelper/appColor";

const labels = dictionary?.components?.stakeholders?.member?.account?.runningActivity;
function RunningActivityView({ isLoading, setIsLoading, parentState, setParentState, classes }) {
  const { appState, appDispatch } = useApp();
  const [state, setState] = useState({
    blnOpenModal: false,
    selectedObj: null,
    blnOpenSetterModal: false,
    blnOpenConfirmInvitation: false,
  });
  const lang = appState?.clientInfo?.strLanguage;

  const dialogModal =
    (selectedObj = null) =>
    () => {
      modalOverFllow(!state?.blnOpenModal);
      setState({ ...state, blnOpenModal: !state?.blnOpenModal, selectedObj: selectedObj });
    };

  const dialogSetterModal =
    (selectedObj = null) =>
    () => {
      modalOverFllow(!state?.blnOpenSetterModal);
      setState({ ...state, blnOpenSetterModal: !state?.blnOpenSetterModal, selectedObj: selectedObj });
    };

  const dialogConfirmInvitation =
    (selectedObj = null) =>
    () => {
      modalOverFllow(!state?.blnOpenConfirmInvitation);
      setState({ ...state, blnOpenConfirmInvitation: !state?.blnOpenConfirmInvitation, selectedObj: selectedObj });
    };

  const getDetailsLink = (objDetails) => {
    let link = objDetails?.value?.[lang];
    if (appState?.userInfo?.jsnUserInfo?.bigWorkSector === workSectorID?.gov && objDetails?.gov?.value?.[lang]) {
      link = objDetails?.gov?.value?.[lang];
    } else if (appState?.userInfo?.jsnUserInfo?.bigWorkSector === workSectorID?.private && objDetails?.private?.value?.[lang]) {
      link = objDetails?.private?.value?.[lang];
    }
    return link;
  };

  const handlersGuestStudent = regActivityGuestStudentCtrl({ appDispatch, appState, isLoading, setIsLoading });
  const updateTraineeInfo = handlersGuestStudent.ConfirmInvitationGuest({ activityInfo: state?.selectedObj, parentState, setParentState, closeModal: dialogConfirmInvitation });

  return {
    lstElm: (
      <React.Fragment>
        {parentState?.lstRunningActivity?.map((item, index) => {
          return (
            <Paper className={classes?.bgBox2} key={item?.objActivity?.id} p-0>
              <Grid container justifyContent="center" spacing={1}>
                <Grid item xs="12">
                  <Typography as="subtitle2" className={classes?.mainTitle} p-0>
                    {item?.objActivity?.value?.[lang]}
                  </Typography>
                </Grid>
                <Grid item xs="12" justifyContent="center">
                  <Typography as="body3" color={App_Light_Text_Color} p-0>
                    ({new Date(item?.lstActiveSubjectOrdered?.[0]?.subjectValue?.date?.value?.eng).getDate()} -{" "}
                    {item?.lstActiveSubjectOrdered?.[item?.lstActiveSubjectOrdered?.length - 1]?.subjectValue?.date?.value?.[lang]} )
                  </Typography>
                </Grid>
                {!appState?.userInfo?.blnIsActivityUser ? (
                  <Grid item pb-3>
                    <Button label={labels?.btnReg?.[lang]} mode="outlined" color={App_info_Color} className={classes?.btnBox} onClick={dialogModal(item)} />
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Grid item pb-3 px-2>
                      <a href={getDetailsLink(item?.objActivity?.details)} target="_blank" rel="noreferrer" className={classes?.btnBoxHref}>
                        <Button label={labels?.btnDetails?.[lang]} mode="outlined" color={App_info_Color} className={classes?.btnBox} />
                      </a>
                    </Grid>
                    <Grid item pb-3 px-2>
                      <Button label={labels?.btnEdit?.[lang]} mode="outlined" color={App_info_Color} className={classes?.btnBox} onClick={dialogModal(item)} />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              {!appState?.userInfo?.jsnUserInfo?.blnIsFirstSections || !parentState?.lstUserRegActivity?.[index]?.jsnGuestInfo.blnIsFirstSections ? (
                <React.Fragment>
                  <img src={lineImage} alt="" className={classes?.imgLine2} />
                  <Grid container justifyContent="center" p-2>
                    <Grid item xs="12" md="10">
                      <Typography as="body3" className={classes?.labelModal} color={App_Light_Text_Color} p-0>
                        <span className={classes?.note}>{labels?.note?.[lang]}</span>
                        {item?.objActivity?.note?.value?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item xs="12">
                      <Button
                        label={item?.objActivity?.noteBtn?.value?.[lang]}
                        mode="outlined"
                        color={App_info_Color}
                        className={classes?.btnBox}
                        onClick={dialogSetterModal(item)}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              ) : null}

              {!parentState?.lstUserRegActivity?.[index]?.jsnGuestInfo.blnIsConfirmFirstSections ||
              parentState?.lstUserRegActivity?.[index]?.jsnGuestInfo.blnIsConfirmInvitation ? null : (
                <React.Fragment>
                  <img src={lineImage} alt="" className={classes?.imgLine2} />
                  <Grid container justifyContent="center" p-2>
                    <Grid item>
                      <Typography as="body3" className={classes?.labelModal} color={App_Light_Text_Color} p-0>
                        {item?.objActivity?.invitation?.value?.[lang]}
                      </Typography>
                    </Grid>
                    {parentState?.lstUserRegActivity?.[index]?.jsnGuestInfo.blnIsConfirmInvitation ? null : (
                      <Grid item xs="12">
                        <Button
                          label={item?.objActivity?.invitationBtn?.value?.[lang]}
                          mode="outlined"
                          color={App_info_Color}
                          className={classes?.btnBox}
                          onClick={dialogConfirmInvitation(item)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </React.Fragment>
              )}

              <img src={lang === "arb" ? robot8 : robot7} alt="" className={classes?.robotImg} />
            </Paper>
          );
        })}
      </React.Fragment>
    ),
    modal: (
      <React.Fragment>
        <Modal open={state?.blnOpenModal} eventClose={dialogModal()} className={classes?.modal}>
          <ModalHeader className={classes?.modalHeader}>
            <Typography as="subtitle3" color={App_info_Color} className={classes?.labelModal} key={state?.selectedObj?.objActivity?.value?.[lang]}>
              {state?.selectedObj?.objActivity?.value?.[lang]}
            </Typography>
          </ModalHeader>
          <ModalBody>
            <RegActivityGuestView
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              parentState={parentState}
              setParentState={setParentState}
              activityInfo={state?.selectedObj}
              closeModal={dialogModal()}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    ),

    modalStudent: (
      <React.Fragment>
        <Modal open={state?.blnOpenSetterModal} eventClose={dialogSetterModal()} className={classes?.modal}>
          <ModalHeader className={classes?.modalHeader}>
            <Typography as="subtitle3" color={App_info_Color} className={classes?.labelModal}>
              {state?.selectedObj?.objActivity?.noteBtn?.value?.[lang]}
            </Typography>
          </ModalHeader>
          <ModalBody>
            <RegActivityGuestStudentView
              appState={appState}
              appDispatch={appDispatch}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              parentState={parentState}
              setParentState={setParentState}
              activityInfo={state?.selectedObj}
              closeModal={dialogSetterModal()}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    ),
    modalStudentConfirmInvitation: (
      <React.Fragment>
        <Modal
          open={state?.blnOpenConfirmInvitation}
          eventClose={dialogConfirmInvitation()}
          className={classes?.modal}
          size="sm"
          sx={{ minHeight: "50px !important", maxWidth: "85% !important" }}
        >
          <ModalBody>
            <Grid container justifyContent="center" py-2>
              <Grid item>
                <Typography as="subtitle2">{labels?.confirmInvitation?.[lang]}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item>
                <Button
                  label={dictionary?.shared?.confirmationMsg?.yesSure?.[lang]}
                  onClick={updateTraineeInfo}
                  py-0
                  color={App_info_Color}
                  mode="outlined"
                  className={classes?.btnBox}
                />
              </Grid>
              <Grid item>
                <Button
                  label={dictionary?.shared?.confirmationMsg?.no?.[lang]}
                  onClick={dialogConfirmInvitation()}
                  py-0
                  color={App_Gray_Color}
                  mode="outlined"
                  className={classes?.btnBox}
                />
              </Grid>
            </Grid>
          </ModalBody>
        </Modal>
      </React.Fragment>
    ),
  };
}

export default RunningActivityView;
