import { fetchData } from "../appData/appFetch";

const objTableName = {
  "tblCategory": "tblCategory",
  "tblSystem": "tblSystem",
  "tblUser": "tblUser",
  "tblMail": "tblMail",
  "tblActivity": "tblActivity",
  "tblSubscription": "tblSubscription",
  "tblRequestedToJoin": "tblRequestedToJoin",
  "tblPartner": "tblPartner",
  "tblError": "tblError",
  "tblContact": "tblContact",
  "tblStatistic": "tblStatistic",
  "tblPartnershipTrack": "tblPartnershipTrack",
  "tblActivityGuest": "tblActivityGuest",
  "tblCertificate": "tblCertificate",
};

export const objTableAttributes = {
  "tblCategory": { full: ["id", "bigID", "strName", "jsnValue", "bigParentID", "jsnParentSeries", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblSystem": { full: ["id", "bigAppID", "bigSystemID", "bigCountryID", "jsnSponsorCatalogue", "strSystemUrlCode", "jsnSystemName", "jsnSystemConfig", "jsnLstAnnouncement", "jsnLstNotification", "jsnNavigation", "jsnSocialMedia", "jsnPermissions", "jsnLanguage", "jsnContact", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblUser": { full: ["id", "bigAppID", "bigSystemID", "bigUserID", "bigUserRoleID", "bigUserStatusID", "bigManagerUserStatusID", "bigStartupUserStatusID", "strUserPassword", "strUserEmail", "strUserPhone", "strUsername", "jsnUserInfo", "txtLoginKeys", "txtLoginIP", "txtPushSubscription", "intNumLoginAttempt", "blnIsAccountLocked", "dtmAccountLocked", "strLastLoginUserAgent", "strLastLoginIPAddress", "dtmLastLogin", "txtChangePasswordInfo", "txtLoginHistoryInfo", "blnAddToHomePage", "blnIsManager", "blnIsStudent", "blnIsStartUp", "blnIsSponsor", "blnIsActivityUser", "blnIsResearcher", "strTrainingTypeCode", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate", "jsnResumeInfo", "jsnPortfolio"] },
  "tblMail": { full: ["id", "bigMailID", "bigSystemID", "jsnMailInfo", "blnIsSeen", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblActivity": { full: ["id", "bigActivityID", "bigSystemID", "jsnActivityInfo", "blnIsArchive", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblSubscription": { full: ["id", "bigSystemID", "bigSubscriptionID", "strSubscriptionEmail", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblRequestedToJoin": { full: ["id", "bigAppID", "bigSystemID", "bigUserID", "bigUserRoleID", "strUserEmail", "strUserPhone", "strUsername", "jsnUserInfo", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblPartner": { full: ["id", "bigAppID", "bigSystemID", "bigPartnerID", "jsnPartnerInfo", "intTraineesCount", "intManagerTrainCount", "bigPartnerStatusID", "blnIsSeen", "blnIsConfirmed", "blnVewLogo", "strLogoImg", "jsnSupportTrans", "intSupportTransStatus", "blnIsFullstackSponsor", "blnIsManagerSponsor", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblError": { full: ["id", "strAppType", "strPath", "jsnDetail", "jsnUserInfo", "blnIsSolved", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblContact": { full: ["id", "bigContactID", "bigSystemID", "bigUserID", "blnForSystem", "strContactEmail", "jsnContactInfo", "blnIsDeleted", "blnIsSeen", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblStatistic": { full: ["id", "bigStatisticID", "strStatisticType", "bigSystemID", "intCountMail", "intCountSubscription", "intCountJoinUser", "intCountJoinMember", "intCountJoinEntrepreneur", "intCountJoinStudent", "intCountJoinToSymposium", "intCountJoinPartner", "intCountJoinManager", "intCountJoinResearcher", "intCountJoinUserAll", "intCountJoinEntrepreneurAll", "intCountJoinManagerAll", "intCountJoinSponsorAll", "intCountJoinStudentAll", "intCountJoinResearcherAll", "intCountSupportClk", "intSponsorRegClk", "intCountJoinPartnerFullstack", "intCountJoinPartnerManager", "intCountTransPartnerFullstack", "intCountTransPartnerManager", "intCountActivityGuest", "intCountActivityGuestAll", "intCountVistMediaPage", "intCountVistCertificateGenerator", "intCountUsedCertificateGenerator", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblPartnershipTrack": { full: ["id", "bigTrackID", "bigAppID", "bigSystemID", "bigTrackStatusID", "intUpdateStatusNumber", "jsnTrackingHistory", "jsnPartnershipTrackInfo", "blnAAIOTContact", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblActivityGuest": { full: ["id", "bigActivityGuestID", "bigActivityType", "strUserEmail", "strUserPhone", "strUsername", "jsnGuestInfo", "bigWorkSector", "strPlaceName", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblCertificate": { full: ["id", "strID", "jsnInfo", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
};

const objResolverName = {
  tblCategory: {
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblSystem: {
    aaiotAppSystemGetOneFK: { aaiotAppSystemGetOneFK: "qca" },
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblUser: {
    aaiotAppUserAdd: { aaiotAppUserAdd: "mi" },
    aaiotAppUserUpdate: { aaiotAppUserUpdate: "mci" },
    aaiotAppUserResetPassword: { aaiotAppUserResetPassword: "mci" },
    aaiotAppUpdateSpecialUserFeature: { aaiotAppUpdateSpecialUserFeature: "mci" },
    aaiotAppUpdateLoginUserConfig: { aaiotAppUpdateLoginUserConfig: "mci" },
    aaiotItcUserAdd: { aaiotItcUserAdd: "mi" },
    itcAppUserAdd: { itcAppUserAdd: "mi" },
    itcAppUserUpdate: { itcAppUserUpdate: "mci" },
    itcAppUserResetPassword: { itcAppUserResetPassword: "mci" },
    itcAppUpdateSpecialUserFeature: { itcAppUpdateSpecialUserFeature: "mci" },
    itcAppUpdateLoginUserConfig: { itcAppUpdateLoginUserConfig: "mci" },
    aaiotAppUserLogin: { aaiotAppUserLogin: "qc" },
    aaiotAppUserAll: { aaiotAppUserAll: "qca" },
    aaiotAppUserOne: { aaiotAppUserOne: "qca" },
    aaiotAppInitUserAccount: { aaiotAppInitUserAccount: "qca" },
    itcAppUserLogin: { itcAppUserLogin: "qc" },
    itcAppFindLoginUserConfig: { itcAppFindLoginUserConfig: "qc" },
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblMail: {
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblActivity: {
    aaiotActivityGuestAdd: { aaiotActivityGuestAdd: "mi" },
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblSubscription: {
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblRequestedToJoin: {
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblPartner: {
    aaiotPartnerAdd: { aaiotPartnerAdd: "mi" },
    aaiotAccountPartnerUpdate: { aaiotAccountPartnerUpdate: "mci" },
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblError: {
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblContact: {
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblStatistic: {
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblPartnershipTrack: {
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblActivityGuest: {
    aaiotActivityGuestAdd: { aaiotActivityGuestAdd: "mi" },
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblCertificate: {
    aaiotAppAdd: { aaiotAppAdd: "mi" },
    aaiotAppAddBulk: { aaiotAppAddBulk: "mi" },
    aaiotAppUpdate: { aaiotAppUpdate: "mci" },
    aaiotAppIncDecInt: { aaiotAppIncDecInt: "mca" },
    aaiotAppServerConfigUpdate: { aaiotAppServerConfigUpdate: "mci" },
    aaiotAppDelete: { aaiotAppDelete: "mc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    aaiotAppFindOne: { aaiotAppFindOne: "qca" },
    aaiotAppFindAll: { aaiotAppFindAll: "qca" },
    aaiotAppServerConfigFindOne: { aaiotAppServerConfigFindOne: "qca" },
    aaiotAppServerConfigFindOneGraphqlSchemaAndTables: { aaiotAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
};

 /* 
 q | m
 i input
 c condition
 a attribute
 */

/**
 *
 * @type {import("./GenerateQueryType.d.ts").generateQueriesType}
 */
export function generateQueries(tableName, appInputName = "appInput") {
  return async function returnQuery(strPageInfo, appState, queryName, objInput) {
    const [strQueryName, operation] = Object.entries(objResolverName?.[objTableName[tableName]]?.[queryName])?.[0];
    const queryBody = (strTableName, objectInput, objectCondition, arrAttributes) => `  
  ${String(operation).includes("m") ? "mutation" : "query"}{
    ${strQueryName}(
      ${appInputName}:{
      strTableName: """${strTableName}""",
      ${String(operation).includes("i") ? `strObjectInput: """${objectInput}""",` : ""}
      ${String(operation).includes("c") ? `strObjectCondition: """${objectCondition}""",` : ""}
      ${String(operation).includes("a") ? `strArrayOfStrAttributes: """${arrAttributes}""",` : ""}
     }){
      blnIsResponseEncrypted
      blnIsRequestSuccessful
      strResponseType
      serverResponse
    }
  }`;
   

    return await fetchData(strPageInfo, {
      appName: "aaiotApp",
      tableName: objTableName?.[tableName],
      objectInput: objInput?.objectInput,
      objectCondition: objInput?.objectCondition,
      arrAttributes: objInput?.arrAttributes,
      queryName: queryName,
      queryBody: queryBody,
      appState: appState,
    });
  };
}
  

/**
 *
 * @type {import("./GenerateQueryType.d.ts").createQueriesType}
 */
export function createQueries(tableName, appInputName = "appInput") {
  return function returnQuery(queryName, objInput) {
    const [strQueryName, operation] = Object.entries(objResolverName?.[objTableName[tableName]]?.[queryName])?.[0];
    const queryBody = (strTableName, objectInput, objectCondition, arrAttributes) => `  
  ${String(operation).includes("m") ? "mutation" : "query"}{
    ${strQueryName}(
      ${appInputName}:{
      strTableName: """${strTableName}""",
      ${String(operation).includes("i") ? `strObjectInput: """${objectInput}""",` : ""}
      ${String(operation).includes("c") ? `strObjectCondition: """${objectCondition}""",` : ""}
      ${String(operation).includes("a") ? `strArrayOfStrAttributes: """${arrAttributes}""",` : ""}
     }){
      blnIsResponseEncrypted
      blnIsRequestSuccessful
      strResponseType
      serverResponse
    }
  }`;
   

    return {queryBody, queryName}
  };
}