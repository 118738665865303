import { makeStyle } from "@cbmisorg/styles";
import * as appStyle from "../../../util/appStyle";

export const objStyle = makeStyle({
  secDark: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: "calc(100vh - 0px)",
    overflowY: "auto",
    margin: "0px auto",
    padding: "0px",
    backgroundColor: "rgb(0, 2, 36)",
    background: "linear-gradient(155deg, #000224 0%, #010219 55%, #0371a0 100%)",
  },
  mainContainer: {
    width: `${(11.5 / 12) * 100}%`,
    margin: "20px auto 0",
  },
  container: {
    width: `${(10 / 12) * 100}%`,
    color: appStyle?.App_Light_Text_Color,
    ...appStyle.fontsChanga,
    textAlign: "justify",
    paddingInlineEnd: "20px",
    xs: {
      width: `${(11 / 12) * 100}%`,
    },
  },

});
