import React, { useEffect } from "react";
import {  App_Dark_menu_Color, App_info_Color, App_Primary_Color,} from "../../../util/appStyle";
import { ClassesBuilder } from "@cbmisorg/styles";
import { ClientDirection } from "@cbmisorg/helper";
import { AppBar, GroupBar, Typography} from "@cbmisorg/material";
import { Icon } from "@cbmisorg/icons";
import { useNavigate } from "@cbmisorg/router";
import appRouting from "../../../configuration/routing/appRouting";



function ShredHeader({ title = "", lang = "arb", back = -1, blnBack = true, blnHome = true, blnIsLight=false, blnLang = false, blnLogout = false, changLang, funLogout , sx={}}) {
  const objStyle = {
    appbar: {
      background: "linear-gradient(120deg, #0371a0 0%, #011C35 50%, #0371a0 100%)",
      borderColor: App_info_Color,
      // background: App_info_Color,
      borderBottom:"1px solid",
      position: "fixed",
    },
    appbar2: {
      background: "linear-gradient(120deg, #eee 0%, #efefe 50%, #fefefe 100%)",
      borderColor: App_Primary_Color,
      // background: App_info_Color,
      borderBottom:"1px solid",
      position: "fixed",
      sm:{
        background: "linear-gradient(120deg, #0371a0 0%, #011C35 50%, #0371a0 100%)",
        borderColor: App_info_Color,
      }
    },
    icons: {
      borderRadius: "50%",
      color: blnIsLight? App_Dark_menu_Color : App_info_Color,
      border: blnIsLight?"0px solid":"1px solid",
      borderColor: App_info_Color,
      // color: App_Dark_menu_Color,
    },
  };
  const classes = ClassesBuilder(objStyle, {lang});
  const navigate = useNavigate();

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
  }, [lang]);

  const goPath = (path) => () => {
    if (window?.location?.pathname === appRouting.Account.url) {
      navigate(appRouting.landingPage.url);
    } else {
      navigate(path);
    }
  };

  return (
    <AppBar className={blnIsLight?classes?.appbar2:classes.appbar} dir={"rtl"} sx={sx} >
      <GroupBar position={{ xs: "navbar" }}>{blnHome ? <Icon icon="home" onClick={goPath(appRouting?.landingPage?.url)} className={classes?.icons} size="24px" /> : null}</GroupBar>
      <GroupBar position={{ xs: "navbar" }}>
        {title ? (
          <Typography as="subtitle2" color={blnIsLight?App_Dark_menu_Color:App_info_Color}>
            {title}
          </Typography>
        ) : null}
      </GroupBar>
      <GroupBar position={{ xs: "navbar" }} dir="ltr">
        {blnBack ? <Icon icon="arrowback" onClick={goPath(back)} className={classes?.icons} size="24px" /> : null}
        {blnLogout ? <Icon icon="logout" color={"error"} className={classes?.icons} size="24px" onClick={funLogout} /> : null}
        {blnLang ? <Icon icon="translate" className={classes?.icons} size="24px" onClick={changLang} mx-2 /> : null}
      </GroupBar>
    </AppBar>
  );
}

export default ShredHeader;
