import { ClientDirection, Container, Grid, Line, Typography, useLocation, useParams } from "@cbmisorg/client-app";
import React, {  useEffect } from "react";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import { App_Server_Url_DownloadFiles } from "../../../../appHelper/appVariables";
import { useApp } from "../../../../../client/configuration/contextapi/context";
const labels = dictionary?.components?.public?.landingPage?.home?.events;
function Event() {
  const { getNewSystemInfo } = useCheckSystemInfo();
  const { appState, appDispatch } = useApp();
  const { id } = useParams();
  const { state } = useLocation();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const lstActivities = appState?.clientInfo?.objSystemInfo?.lstActivities || [];
  const index = lstActivities?.findIndex((item) => String(item.bigActivityID) === String(id));
  const objActivty = state?.objActivty ? state?.objActivty : index > -1 ? lstActivities?.[index] : {};

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
  }, [lang]);

  const intData = async () => {
    if (!state?.objActivty) {
      if (!appState?.clientInfo?.objSystemInfo || !Object.keys(appState?.clientInfo?.objSystemInfo)?.length) {
        appState.clientInfo.objSystemInfo = await getNewSystemInfo();
        appDispatch();
      }
    }
  };

  useEffect(() => {
    intData();
  }, []);
  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} />
      <Container py-5 key={JSON.stringify(lstActivities)}>
        <Grid container id="ourGoal" px-0 spacing={0} pb-xs-10 mb-xs-10>
          <Grid item xs="12" container justify="center" pb-xs-10 mb-xs-10>
            <Container>
              <Grid container pt-5 pb-8 justify={"center"}>
                <Grid item xs="12">
                  <Typography as="subtitle1" color="primary">
                    {objActivty?.jsnActivityInfo?.title?.[lang]}
                  </Typography>
                </Grid>
              </Grid>
              <Line
                label={
                  <Typography as="caption" p-0>
                    {objActivty?.jsnActivityInfo?.source?.[lang]}
                  </Typography>
                }
              />
              <Grid container spacing={2}>
                {objActivty?.jsnActivityInfo?.listPoint?.map((item, ind) => {
                  return (
                    <Grid item xs="12" key={ind}>
                      <Typography as="body2">{item?.[lang]}</Typography>
                    </Grid>
                  );
                })}
                {!objActivty?.jsnActivityInfo?.link?.intTypeLink || typeof objActivty?.jsnActivityInfo?.link !== "object" ? null : (
                  <Grid item xs="12">
                    <a href={objActivty?.jsnActivityInfo?.link?.url} target="_blank">
                      <Typography as="body2">{objActivty?.jsnActivityInfo.link?.label?.[lang]}</Typography>
                    </a>
                  </Grid>
                )}
              </Grid>
              <Line />
              <Grid container spacing={2}>
                {objActivty?.jsnActivityInfo?.lstMainImg?.map((img, ind) => {
                  return (
                    <Grid item xs="12" md="4" xl="3">
                      <img src={App_Server_Url_DownloadFiles + img?.path} alt={objActivty?.jsnActivityInfo?.title?.[lang]} width="100%" style={{ minHeight: "100px" }} />
                    </Grid>
                  );
                })}
                {objActivty?.jsnActivityInfo?.lstImages?.map((img, ind) => {
                  return (
                    <Grid item xs="12" md="4" xl="3">
                      <img src={App_Server_Url_DownloadFiles + img?.path} alt={objActivty?.jsnActivityInfo?.title?.[lang]} width="100%" style={{ minHeight: "100px" }} />
                    </Grid>
                  );
                })}
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Event;
