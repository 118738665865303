import React, { useEffect, useState } from "react";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { Button, Grid, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/material";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import researcherSetterModalCtrl from "./researcherSetterModal.controller";
import { App_info_Color } from "../../../../../../util/appStyle";
import Uploader from "../../../../../../../old/components/sharedUi/uploader/Uploader";
import { objUploadType } from "../../../../../../configuration/appVariables";
import { Icon } from "@cbmisorg/icons";
import { modalOverFllow } from "../../../../../../util/appFunctions";

const labels = dictionary?.components?.stakeholders?.member?.account?.researcherSetter;

export default function ResearcherSetterModalView({ parentState, isLoading, setIsLoading,  classes }) {
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const [state, setState] = useState({ blnOpenSetterModal: false, blnIsNew: true, lstFirstProofSection: [], blnHoverd: false });
  const uploaderFirstProofSection = Uploader(state, setState, "lstFirstProofSection", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: dictionary?.components?.uploader?.receipt?.[lang],
    blnHoverd: false,
  });
  const fields = FormData({
    strFullName: { control: "text", validations: { required: true }, blnLanguage: false },
    strPlaceName: { control: "text", validations: { required: true }, blnLanguage: false },
    strPositionTitle: { control: "text", validations: { required: true }, blnLanguage: false },
    bigMajorID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigDegreeID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigGender: { control: "list", validations: { required: true }, blnLanguage: false },
    bigEmploymentID: { control: "list", validations: { required: true }, blnLanguage: false },
  });

  const handlers = researcherSetterModalCtrl({ appDispatch, appState, isLoading, setIsLoading });
  const openModal = handlers.openModal({ fields, setState, state });
  const updateResearcherInfo = handlers.updateResearcherInfo({ fields, setState, state, uploaderFirstProofSection });

  useEffect(() => {
    return () => {
      modalOverFllow();
    };
  }, []);

  
  return {
    openEle: (
      <Paper className={classes?.bgBox}>
        <Grid container justifyContent="center">
          <Grid item>
            <Typography as="subtitle2" className={classes?.mainTitle}>
              {labels?.title?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs="12" md="12" className={classes?.descriptionLightTextContainer} key="trainingContainer">
            <Typography
              as="body2"
              className={`${!parentState?.objPartnerInfo?.jsnSupportTrans?.length ? classes?.descriptionLightText : classes?.descriptionLightText5} ${
                state?.blnHoverd ? classes?.descriptionLightTextActive : ""
              }`}
              key="training"
              onMouseEnter={() => {
                setState({ ...state, blnHoverd: true });
              }}
              onMouseLeave={() => {
                setState({ ...state, blnHoverd: false });
              }}
            >
              {labels?.description?.[lang]}
            </Typography>
            <Grid container justifyContent="center" className={`${classes?.arrowClose} arrowClose`} key="arrowClose_training">
              <Grid item xs="auto" md="0" my-2 key="arrowClose_training_item">
                <Icon icon="KeyboardArrowUp" size="30px" color={App_info_Color} onClick={() => {}} key="arrowClose_icon" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              label={appState?.userInfo?.blnIsResearcher ? dictionary?.shared?.buttons?.editUpd?.[lang] : labels?.btn?.[lang]}
              mode="outlined"
              color={App_info_Color}
              onClick={openModal}
              className={classes?.btnBox}
            />
          </Grid>
        </Grid>
      </Paper>
    ),

    modal: (
      <Modal
        open={state.blnOpenSetterModal}
        eventClose={() => {
          modalOverFllow();
          setState({ ...state, blnOpenSetterModal: false });
        }}
        className={classes?.modal}
      >
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={App_info_Color} className={classes?.labelModal}>
            {labels?.title?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12}>
              <FormField objHandler={fields.strFullName} icon="person" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.strPlaceName} icon="business" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.strPositionTitle} icon="work" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigGender} icon="Wc" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigEmploymentID} icon="work" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigDegreeID} icon="documentScannerRounded" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigMajorID} icon="book" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <React.Fragment></React.Fragment>
          </Grid>

          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item xs="12" pt-8 pb-5>
              <Button
                py-0
                px-10
                label={dictionary?.shared?.buttons?.submitBtn?.[lang]}
                color={App_info_Color}
                onClick={updateResearcherInfo}
                mode="outlined"
                className={classes?.btnBox}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),
  };
}
