import React, { useEffect, useState } from "react";
import { Grid, Loader, Button, Container } from "@cbmisorg/material";
import eCertificateCtrl from "./eCertificate.controller";
import { useApp } from "../../../configuration/contextapi/context";
import { objStyle } from "./eCertificate.style";
import { ClassesBuilder } from "@cbmisorg/styles";
import { dictionary } from "../../../util/appLanguage/dictionary";
import { useParams } from "@cbmisorg/client-app";
import jsPDF from "jspdf";

export default function ECertificatePreviewView() {
  const { appState } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const classes = ClassesBuilder(objStyle, { lang });
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    strFullName: null,
    startX: null,
    startY: null,
    ctxWidth: null,
    ctxHeight: null,
    imageWidth: null,
    imageHeight: null,
    imageTop: null,
    imageLeft: null,
    imageRight: null,
    imageBottom: null,
    strLinkUpload: null,
    blnIsInitDone: false,
  });
  const param = useParams();

  const handlers = eCertificateCtrl({ appState });
  const initCertificatePreview = handlers.initCertificatePreview({ isLoading, setIsLoading, setState, state });

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initCertificatePreview(param?.id);
    }
  }, []);

  const downloadCertificate = async () => {
    const canvas = document.getElementById("my-canvas-name");

    const imgAsUrl = canvas?.toDataURL("image/png");

    const docMulti = new jsPDF({
      unit: "px",
      orientation: "landscape",
      format: [state.imageWidth, state.imageHeight],
      //format: [2480, 3508],
    });

    docMulti.addImage(imgAsUrl, "image/png", 0, 0, state.imageWidth, state.imageHeight);
    docMulti.save(`${state?.strFullName}.pdf`);
  };

  return (
    <>
      <section className={classes?.secDark} style={{ paddingTop: "0px" }}>
        {!isLoading ? null : <Loader backdrop={true} color="secondary" />}

        <Container>
          <Grid container justifyContent="center">
            <Grid item xs="12">
              <Button icon={"download"} label="PDF" py-0 iconSize="23px" size="sm" onClick={downloadCertificate} />
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Grid item p-0 sx={{ overflow: "auto", maxWidth: "100vw", maxHeight: "90vh" }}>
              <img alt="" id={"my-certificate-template"} style={{ zIndex: 5, width: "auto", height: "auto" }} />
              <canvas id={"my-canvas-name"} style={{ /* border: "1px solid red", */ position: "absolute", zIndex: 10, overflow: "auto" }}></canvas>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
}
