import { Alert } from "@cbmisorg/material";
import * as appFunctions from "../../../../../../util/appFunctions";
import * as appVariables from "../../../../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../../../../graphql/schema/GenerateQuery";
import { workSectorCat, workSectorID } from "../../../../../../configuration/appVariables";
import * as appStyle from "../../../../../../util/appStyle";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblActivityGuest = generateQueries("tblActivityGuest");

const labels = dictionary?.components?.public?.regActivityGuest;
export const CtrlRegActivityGuest = ({ appState, appDispatch, isLoading, setIsLoading }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;

  const handler = {
    initData: async ({ activityInfo, state, setState, parentState, fields, WorkSectorOptionComponent, SubjectOptionComponent, closeModal }) => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], appStyle.alertStyle);
          return;
        }
        //#region tblCategory & filter activity
        setIsLoading(true);

        if (activityInfo?.lstActiveSubjectOrdered?.length) {
          const lstCurentSubject = activityInfo?.lstActiveSubjectOrdered?.filter((item) => {
            const subjectDate = appFunctions.blnIsTodayBiggerThanTheDate(new Date(item?.subjectValue?.date?.value?.["eng"]));
            return !subjectDate && item?.subjectValue?.blnView?.value?.["eng"] === "true";
          });

          if (lstCurentSubject?.length === 0) {
            Alert.viewAlert(labels?.alertEndWeek?.[lang], "info", appLangDirection?.[lang], appStyle.alertStyle);
            closeModal();
            return;
          }
          state.lstCurentSubject = lstCurentSubject;

          if (activityInfo?.objActivity?.type?.value?.["eng"]) {
            fields?.lstSubject?.setControl(activityInfo?.objActivity?.type?.value?.["eng"]?.replace(" ", ""));

            if (activityInfo?.objActivity?.type?.value?.["eng"] !== "none" && state.lstCurentSubject?.length) {
              fields?.lstSubject?.setValidation("required", true);
            }
          }
        }
        //#endregion

        //#region setValue if User Login
        const activityIndex = parentState?.lstUserRegActivity?.findIndex((item) => String(item?.bigActivityType) === String(activityInfo?.objActivity?.id));

        if (activityIndex > -1) {
          const objInfo = parentState?.lstUserRegActivity?.[activityIndex];

          if (objInfo?.bigWorkSector) {
            fields?.bigWorkSector?.setValue(objInfo?.bigWorkSector);
            handler?.handelWorkSectorChange({
              fields,
              state,
              setState,
              WorkSectorOptionComponent,
              SubjectOptionComponent,
              initObjTarget: objInfo?.jsnGuestInfo?.objTargted,
              parentState,
              activityInfo,
            })(false);
          }

          if (objInfo?.jsnGuestInfo?.objTargted) {
            fields?.lstTargeted?.setValue(objInfo?.jsnGuestInfo?.objTargted);
          }

          if (objInfo?.strPlaceName) {
            fields?.strPlaceName?.setValue(objInfo?.strPlaceName);
          } else if(appState?.userInfo?.jsnUserInfo?.strPlaceName){
            fields?.strPlaceName?.setValue(appState?.userInfo?.jsnUserInfo?.strPlaceName)
          }

          if (objInfo?.jsnGuestInfo?.objSubject?.length) {
            fields?.lstSubject?.setValue(objInfo?.jsnGuestInfo?.objSubject);
          }
        } else {
          if (appState?.userInfo?.jsnUserInfo?.bigWorkSector) {
            fields?.bigWorkSector?.setValue(appState?.userInfo?.jsnUserInfo?.bigWorkSector);
            handler?.handelWorkSectorChange({ fields, state, setState, WorkSectorOptionComponent, SubjectOptionComponent })();
          }
          if (appState?.userInfo?.jsnUserInfo?.strPlaceName) {
            fields?.strPlaceName?.setValue(appState?.userInfo?.jsnUserInfo?.strPlaceName);
          }
        }

        //#endregion

        setState({ ...state });
        setIsLoading(false);
        appDispatch();
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "initData-ERROR", error);
        setIsLoading(false);
      }
    },
    addGuest: async ({ state, setState, fields, closeModal, activityInfo, parentState, setParentState }) => {
      try {
        //#region Form Valid
        if (!fields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], appStyle?.alertStyle);
          return;
        }
        //#endregion
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], appStyle.alertStyle);
          return;
        }
        setIsLoading(true);

        //#region  get SubjectValue  by control type
        const strPlaceName =fields?.strPlaceName?.getValue() ||  appState?.userInfo?.jsnUserInfo?.strPlaceName
        const getSubjectValue = () => {
          let lstValue = [];
          if (activityInfo?.objActivity?.type?.value?.["eng"] === "radio") {
            lstValue.push(Number(fields?.lstSubject?.getValue()?.key));
          } else if (activityInfo?.objActivity?.type?.value?.["eng"] === "checkbox") {
            lstValue = fields?.lstSubject?.getValue()?.map((item) => Number(item.key));
          }
          return appFunctions.uniq_fast(lstValue);
        };
        //#endregion
        const result = await tblActivityGuest(strPageInfo, appState, "aaiotActivityGuestAdd", {
          objectInput: {
            //#region tblUser Information
            bigAppID: appVariables.App_ID,
            bigSystemID: appVariables.aaiot_System_ID,
            bigUserID: appState?.userInfo?.bigUserID,
            strUserEmail: appState?.userInfo?.strUserEmail,
            //strUserPhone: "strUserPhone",
            //strUsername: "strUsername",

            bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
            strPlaceName: strPlaceName,
            // intUserRoleFK: appVariables.objUserRole.Member,

            blnIsUserLoggedIn: appState?.clientInfo?.blnIsUserLoggedIn,
            // jsnUserInfo: {
            //   //reset values
            // },

            // blnIsManager: false, //String(fields?.lstTargeted?.getValue()?.key) === String(appVariables.workSectorCat.manager),
            // blnIsStudent: false, //String(fields?.lstTargeted?.getValue()?.key) === String(appVariables.workSectorCat.student),
            // blnIsStartUp: false, //String(fields?.lstTargeted?.getValue()?.key) === String(appVariables.workSectorCat.programer),
            // blnIsSponsor: false, //String(fields?.bigWorkSector?.getValue()?.key) === String(appVariables.workSectorID.gov),

            blnIsActivityUser: true,
            //#endregion
            ///////////////////////////////////////////////////
            //#region tblActivityGuest information
            bigActivityType: activityInfo?.objActivity?.id,
            jsnGuestInfo: {
              objSubject: getSubjectValue(),
              objTargted: fields.lstTargeted?.getValue()?.key,
            },
            //#endregion
            ///////////////////////////////////////////////////
            //#region Statistic ??
            lstStatisticFelidIfGuestActivityNew: ["intCountActivityGuest", "intCountActivityGuestAll"],
            //#endregion
          },
        });

        if (!result?.blnIsRequestSuccessful) {
          if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], appStyle.alertStyle);
          } else {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], appStyle.alertStyle);
          }
          setIsLoading(false);
          return;
        }

        const updateMsge =
          Array.isArray(parentState.lstUserRegActivity) && parentState.lstUserRegActivity?.length
            ? dictionary?.shared?.alertFetchNote?.updated_innfo_Successfully?.[lang]
            : labels?.alertThanke?.[lang];
        if (!Array.isArray(parentState.lstUserRegActivity)) {
          parentState.lstUserRegActivity = [];
        }
        if (!parentState.lstUserRegActivity?.length) {
          parentState.lstUserRegActivity.push(result?.response?.guestInfo);
        } else {
          for (let i = 0; i < parentState.lstUserRegActivity.length; i++) {
            if (String(parentState.lstUserRegActivity?.[i]?.bigActivityType) === String(activityInfo?.objActivity?.id)) {
              parentState.lstUserRegActivity[i] = { ...parentState.lstUserRegActivity?.[i], ...result?.response?.guestInfo };
            }
          }
        }
        setParentState({ ...parentState });
        setIsLoading(false);
        setState({ ...state });
        Alert.viewAlert(updateMsge, "success", appLangDirection?.[lang], appStyle.alertStyle);
        fields?.controller?.resetForm();
        appState.clientInfo.blnIsUserLoggedIn = true;
        appState.userInfo = result.response?.userInfo;
        appDispatch();
        closeModal();
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "addGuest-ERROR", error);
        setIsLoading(false);
      }
    },
    onChangeSubject:
      ({ fields, state }) =>
      (event) => {
        try {
          const bigSubjectID = Number(event?.target?.id);
          if (
            Array.isArray(state?.lstDefaultActivitySubject) &&
            state?.lstDefaultActivitySubject?.length &&
            !event?.target?.checked &&
            state.lstDefaultActivitySubject?.includes(bigSubjectID)
          ) {
            let lstSubject = fields.lstSubject.getValue();
            lstSubject = Array.isArray(lstSubject) ? lstSubject : [];
            lstSubject.push(bigSubjectID);
            fields.lstSubject.setValue(lstSubject);
          }
        } catch (error) {
          appFunctions.logError(strPageInfo, "onChangeSubject-ERROR", error);
        }
      },
    handelWorkSectorChange:
      ({ fields, state, setState, parentState, activityInfo, WorkSectorOptionComponent, SubjectOptionComponent, blnTargetedChange = false, initObjTarget = null }) =>
      (blnChanged = true) => {
        try {
          const bigWorkSectorVal = fields?.bigWorkSector?.getValue();

          if (!blnTargetedChange) {
            //#region rest Value
            fields.lstTargeted?.setValue("");
            //#endregion
            //#region set Option for lstTargeted field
            let optLst = [];
            if (bigWorkSectorVal?.key === workSectorID?.gov) {
              optLst = [
                { key: workSectorCat?.leader, value: dictionary?.shared?.app?.leader, jsx: () => <WorkSectorOptionComponent type="leader" lang={lang} /> },
                { key: workSectorCat?.employee, value: dictionary?.shared?.app?.employee, jsx: () => <WorkSectorOptionComponent type="employee" lang={lang} /> },
                { key: workSectorCat?.academic, value: dictionary?.shared?.app?.academic, jsx: () => <WorkSectorOptionComponent type="academic" lang={lang} /> },
              ];
            } else if (bigWorkSectorVal?.key === workSectorID?.prvt) {
              optLst = [
                { key: workSectorCat?.manager, value: dictionary?.shared?.app?.manager, jsx: () => <WorkSectorOptionComponent type="manager" lang={lang} /> },
                { key: workSectorCat?.employee, value: dictionary?.shared?.app?.employee, jsx: () => <WorkSectorOptionComponent type="employee" lang={lang} /> },
                { key: workSectorCat?.academic, value: dictionary?.shared?.app?.academic, jsx: () => <WorkSectorOptionComponent type="academic" lang={lang} /> },
                { key: workSectorCat?.programer, value: dictionary?.shared?.app?.programer, jsx: () => <WorkSectorOptionComponent type="programer" lang={lang} /> },
                { key: workSectorCat?.student, value: dictionary?.shared?.app?.student, jsx: () => <WorkSectorOptionComponent type="student" lang={lang} /> },
              ];
            }
            fields?.lstTargeted?.setOptions(optLst, true);
            fields?.lstTargeted?.setValue(initObjTarget ? initObjTarget : optLst?.[0]?.key);
            //#endregion
          }
          //#region set Option for fields field
          const subjectLstOption = [];
          const arrayValueKey = [];
          const bigTargetedVal = fields?.lstTargeted?.getValue();
          if (bigTargetedVal?.key === appVariables.workSectorCat?.student) {
            fields?.strPlaceName?.setLabel(labels?.uniName);
          } else {
            fields?.strPlaceName?.setLabel(dictionary?.formapi?.strPlaceName?.label);
          }

          for (let index = 0; index < state?.lstCurentSubject.length; index++) {
            const element = state?.lstCurentSubject?.[index]?.subjectValue;

            if (
              JSON.parse(element?.bigworkSectorID?.value?.["eng"])?.includes(bigWorkSectorVal?.key) ||
              String(element?.bigworkSectorID?.value?.["eng"])?.includes(String(bigWorkSectorVal?.key))
            ) {
              subjectLstOption.push({
                key: element?.id,
                value: element?.value,
                jsx: () => <SubjectOptionComponent key={element?.id} element={element} lang={lang} state={state} setState={setState} />,
              });
            }

            if (
              element?.defaultCatTargeted &&
              (JSON.parse(element?.defaultCatTargeted?.value?.["eng"])?.includes(bigTargetedVal?.key) ||
                String(element?.defaultCatTargeted?.value?.["eng"])?.includes(String(bigTargetedVal?.key)))
            ) {
              arrayValueKey.push(element?.id);
            }

            if (appState?.userInfo?.jsnUserInfo?.blnIsFirstSections && index === state?.lstCurentSubject.length - 1) {
              arrayValueKey.push(element?.id);
            }
          }

          state.lstDefaultActivitySubject = arrayValueKey;

          let intersection = [];
          if (blnChanged) {
            const activityIndex = parentState?.lstUserRegActivity?.findIndex((item) => String(item?.bigActivityType) === String(activityInfo?.objActivity?.id));
            if (activityIndex > -1) {
              let set1 = new Set((fields?.lstSubject?.getValue() || [])?.map((item) => item?.key));
              let set2 = new Set(parentState?.lstUserRegActivity?.[activityIndex]?.jsnGuestInfo?.objSubject || []);
              intersection = [...set1].filter((value) => set2.has(value));
            }
          }

          fields.lstSubject.setOptions(subjectLstOption, true);
          fields.lstSubject.setValue([...intersection, ...arrayValueKey]);
          //#endregion
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "handelWorkSectorChange-ERROR", error);
          setIsLoading(false);
        }
      },
  };

  return handler;
};
