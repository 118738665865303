import React, { useEffect, useState } from "react";
import mngSupportTransCtrl, { strTransactionNeedToModify } from "./mngSupportTrans.controller";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Grid,
  Icon,
  Line,
  Loader,
  MenuItem,
  Modal,
  ModalBody,
  ModalHeader,
  Tabs,
  Typography,
  useParams,
} from "@cbmisorg/client-app";
import { App_Dark_Color, App_Light_blue, App_Primary_Color, App_Text_Color } from "../../../../appHelper/appColor";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import NoResultImg from "../../../sharedUi/noResultImg/NoResultImg";
import * as appFunctions from "../../../../appHelper/appFunctions";
import * as appVariables from "../../../../appHelper/appVariables";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import Uploader from "../../../sharedUi/uploader/Uploader";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import excelIcon from "../../../../assets/images/excel-icon.png";
import { useApp } from "../../../../../client/configuration/contextapi/context";
import { getSponsorTypeAccordingToTraineeNum } from "../../../../../client/util/appFunctions";

export default function MngSupportTransView() {
  useCheckSystemInfo();
  useCheckCat();

  const { appState, appDispatch } = useApp();
  const { strTrainingType } = useParams();
  const lang = appState?.clientInfo?.strLanguage;
  const labels = dictionary?.components?.mngSupportTrans;
  const objCat = appFunctions.getCategoryCache();
  const objSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsFetchDone: false,

    lstTrans: [],
    objPartnerInfo: {},

    intNumNew: 0,
    intWaitingNum: 0,
    intApprovedNum: 0,
    intNumReject: 0,

    bigCurrentTab: appVariables?.objSupportTransStatus?.Under_Process,

    blnOpenEditModal: false,
    objSelectedPartnerInfo: {},
    intEditIndex: 0,

    lstPaymentVoucher: [],
    lstReceiptVoucher: [],

    intNumViewItem: 0,

    blnOpenTransction: false,
  });

  const fldSearch = FormData({
    strSearch: {
      strControl: "text",
      objLanguage: false,
    },
  });

  const fields = FormData({
    strSupportCatType: { strControl: "list", objLanguage: false, objValidations: { required: true } },
    transactionDecision: {
      strControl: "radio",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: appVariables.objSupportTransStatus.Approved, value: labels?.decisionApproved?.[lang] },
        { key: strTransactionNeedToModify, value: labels?.decisionNeedModify?.[lang] },
        { key: appVariables.objSupportTransStatus.Rejected, value: labels?.decisionRejected?.[lang] },
      ],
    },
    trainingNumberAdmin: { strControl: "text", objLanguage: false, objValidations: { onlyNumbers: true, } },
    intTrainingProgPrice: { strControl: "text", objLanguage: false, objValidations: { onlyNumbers: true, required: true } },
    strContact: { strControl: "checkbox", objLanguage: false, lstOption: [{ key: "true", value: labels?.contact?.[lang] }] },
  });

  const uploaderPayment = Uploader(state, setState, "lstPaymentVoucher", "", {
    fileType: appVariables.objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    title: labels?.paymentVoucher?.[lang],
  });

  const uploaderReceipt = Uploader(state, setState, "lstReceiptVoucher", "", {
    fileType: appVariables.objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    title: labels?.receiptVoucher?.[lang],
  });

  const handlers = mngSupportTransCtrl({ appState, appDispatch });
  const initData = handlers.initData({ isLoading, setIsLoading, setState, state, fields, strTrainingType });
  const openEditDialog = handlers.openEditDialog({ setState, state, fields, strTrainingType });
  const sortResult = handlers.sortResult({ state, fldSearch });
  const updateSupportTranToDB = handlers.confirmUpdateStatus({ state, setState, isLoading, setIsLoading, fields, uploaderPayment, uploaderReceipt });
  const getPartnerSponsorPlanDetail = handlers.getPartnerSponsorPlanDetail({ objCat });
  const exportExcelFile = handlers.exportExcelFile({ state });
  const onChangeCatType = handlers.onChangeCatType({ objCat, fields, strTrainingType });
  const onChangeTraneeCount = handlers.onChangeTraneeCount({ objCat, fields, strTrainingType });

  useEffect(() => {
    if (!state.blnIsFetchDone) {
      initData();
    }
    document.body.classList.add("paddingBottom");

  }, []);

  return (
    <>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false} />

      <Container py-3>
        {!isLoading ? null : <Loader color={App_Dark_Color} backdrop={true} />}

        {!state.lstTrans?.length ? (
          <Grid container justify={"center"}>
            <NoResultImg xs={8} md={6} lg={6} />
          </Grid>
        ) : (
          <>
            <Grid container justify={"center"}>
              <Grid item xs={12} p-0 sx={{ ".cbmis-tabs": { width: "100% !important", maxWidth: "99% !important", alignItems: "center" } }}>
                <Tabs
                  mode="box"
                  activeColor="primary"
                  p-0
                  dir={lang === "arb" ? "rtl" : "ltr"}
                  currentTab={1}
                  tabsContent={[
                    {
                      tabLabel: (
                        <Grid container>
                          <Grid item p-0 xs="9" sm="auto">
                            {labels?.underProcess?.[lang]}&nbsp;
                          </Grid>
                          <Grid item p-0 xs="2" sm="auto" px-1>
                            <Box py-0 px-2 m-0 color=" " sx={{ background: App_Primary_Color }}>
                              <Typography as="caption" color="light" p-0 m-0>
                                <b>{state?.intWaitingNum || 0}</b>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      ),
                      onChange: () => setState({ ...state, bigCurrentTab: appVariables.objSupportTransStatus.Under_Process }),
                    },
                    {
                      tabLabel: (
                        <Grid container>
                          <Grid item p-0 xs="9" sm="auto">
                            {labels?.approved?.[lang]}&nbsp;&nbsp;
                          </Grid>
                          <Grid item p-0 xs="2" sm="auto" px-1>
                            <Box py-0 px-2 m-0 color=" " sx={{ background: App_Primary_Color }}>
                              <Typography as="caption" color="light" p-0 m-0>
                                <b>{state?.intApprovedNum || 0}</b>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      ),
                      onChange: () => setState({ ...state, bigCurrentTab: appVariables.objSupportTransStatus.Approved }),
                    },
                    {
                      tabLabel: (
                        <Grid container>
                          <Grid item p-0 xs="9" sm="auto">
                            {labels?.rejected?.[lang]}&nbsp;
                          </Grid>
                          <Grid item p-0 xs="2" sm="auto" px-1>
                            <Box py-0 px-2 m-0 color=" " sx={{ background: App_Primary_Color }}>
                              <Typography as="caption" color="light" p-0 m-0>
                                <b>{state?.intNumReject || 0}</b>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      ),

                      onChange: () => setState({ ...state, bigCurrentTab: appVariables.objSupportTransStatus.Rejected }),
                    },
                  ]}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={String(state.bigCurrentTab) !== String(appVariables.objSupportTransStatus?.Approved) || !state?.intApprovedNum ? 12 : 11}
                sx={{ background: "#ffffff" }}
                p-0
              >
                <FormField objHandler={fldSearch?.strSearch} endIcon={"search"} dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
              {String(state.bigCurrentTab) !== String(appVariables.objSupportTransStatus?.Approved) || !state?.intApprovedNum ? null : (
                <Grid item xs="1" md="1" justify={"end"} mt-1 px-1>
                  <span onClick={exportExcelFile}>
                    <img src={excelIcon} alt="" width={"25px"} style={{ borderRadius: "4px" }} />
                  </span>
                </Grid>
              )}
            </Grid>

            <Grid container justify={"center"}>
              {(state.lstTrans || [])?.map((trans, intIndex) => {
                const objPartnerInfo = state.objPartnerInfo?.[trans?.bigPartnerID];
                const sponsorDetail = getPartnerSponsorPlanDetail(objPartnerInfo, trans);

                if (intIndex === 0) {
                  state.intNumViewItem = 0;
                }
                if (!sortResult(trans, objPartnerInfo)) {
                  if (!state.intNumViewItem && intIndex >= state.lstTrans?.length - 1) {
                    return <NoResultImg key={trans?.id} xs={6} />;
                  }
                  return null;
                }
                ++state.intNumViewItem;

                return (
                  <Grid item xs="12" key={trans?.id}>
                    <MenuItem
                      sx={{
                        width: "100%",
                        border: `1px solid ${App_Text_Color}`,
                        background: String(trans?.intSupportTransStatus) === String(appVariables?.objSupportTransStatus?.Send_New) ? App_Light_blue : "#ffffff",
                        color: App_Primary_Color,
                      }}
                      outlined
                    >
                      <Grid container justify={"center"}>
                        <Grid item md="11" xs="10">
                          <Grid container>
                            <Grid item xs="8" px-2>
                              <Grid item xs="12" pb-0>
                                <Typography as="caption" pb-0>
                                  {appVariables.workSectorIDLabel?.[objPartnerInfo?.jsnPartnerInfo?.bigWorkSector]?.[lang]}
                                </Typography>
                              </Grid>

                              {objCat?.Root?.toSupport?.[trans?.strTrainingType]?.blnIsTraining?.value?.eng === "false" ? (
                                <Typography as="caption" pb-0 color={sponsorDetail.colorFullstack}>
                                  <b>{state?.objPartnerInfo?.[trans?.bigPartnerID]?.jsnPartnerInfo?.strPlaceName}</b>
                                </Typography>
                              ) : (
                                <Typography as="caption" pb-0 color={sponsorDetail.colorFullstack}>
                                  <b>
                                    {sponsorDetail?.strFullstackSponsorCatLabel}&nbsp;-&nbsp;
                                    {`${Number(trans?.intTraineesCount || 0).toLocaleString()} ${dictionary?.components?.public?.join?.trainingCustomNumber?.[lang]}`}
                                  </b>
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item md={"1"} xs="2">
                          {String(trans?.intSupportTransStatus) !== String(appVariables?.objSupportTransStatus?.Send_New) ? (
                            <Button icon={"priceCheck"} onClick={openEditDialog(trans, intIndex)} />
                          ) : (
                            <Badge mode="dot" sx={{ background: "#FFC700 !important" }}>
                              <Button m-0 icon={"priceCheck"} onClick={openEditDialog(trans, intIndex)} />
                            </Badge>
                          )}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
      </Container>

      <Modal size="xl" open={state.blnOpenEditModal} eventClose={() => setState({ ...state, blnOpenEditModal: false })}>
        <ModalHeader>
          <Grid container justify={"center"} sx={{ width: "85%" }}>
            <Grid item>
              <Typography as="caption" sx={{ textAlign: "center !important", fontWeight: "bold" }}>
                {dictionary?.components?.public?.join?.partnerInfo?.[lang]}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>

        <ModalBody p-0>
          <Grid container justify={"center"} spacing={0} py-2>
            <Grid item>
              {!state?.objSelectedPartnerInfo?.strLogoImg?.[0]?.path ? (
                <Avatar color={"primary"}>
                  <Icon icon={"business"} color="#eee" />
                </Avatar>
              ) : (
                <Avatar src={appVariables.App_Server_Url_DownloadFiles + state?.objSelectedPartnerInfo?.strLogoImg?.[0]?.path} />
              )}
            </Grid>
            <Grid item xs="12">
              <Typography as="subtitle1">
                {state?.objSelectedPartnerInfo?.jsnPartnerInfo?.strPlaceName?.[lang] || state?.objSelectedPartnerInfo?.jsnPartnerInfo?.strPlaceName}
              </Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="caption">{appVariables.workSectorIDLabel?.[state?.objSelectedPartnerInfo?.jsnPartnerInfo?.bigWorkSector]?.[lang] || "-"}</Typography>
            </Grid>
          </Grid>
          <Line my-3 />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                as="subtitle2"
                sx={{
                  borderInlineStart: `5px solid ${App_Primary_Color} !important`,
                  paddingInlineStart: "8px !important",
                  color: App_Primary_Color,
                  fontFamily: "'Hand Font', 'Reem Kufi', sans-serif !important",
                }}
              >
                {labels?.sponsorshipInfo?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography as="caption">
                <b>{labels?.sponserInfo?.supportType?.[lang]}</b> :&nbsp;
              </Typography>
              <Typography as="caption">{objCat?.Root?.toSupport?.[strTrainingType]?.value?.[lang]}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography as="caption">
                <b>{labels?.sponserInfo?.supportCat?.[lang]}</b> :&nbsp;
              </Typography>
              <Typography as="caption">
                {
                  dictionary?.shared?.app.sponserLabelCat?.[
                    state?.lstTrans?.[state?.intEditIndex]?.strSupportCatType ? state?.lstTrans?.[state?.intEditIndex]?.strSupportCatType
                    :getSponsorTypeAccordingToTraineeNum(
                      state?.lstTrans?.[state?.intEditIndex]?.intTraineesCount || state?.lstTrans?.[state?.intEditIndex]?.intTrainingProgPrice,
                      state?.lstTrans?.[state?.intEditIndex]?.strTrainingType
                    )
                  ]?.[lang]
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography as="caption">
                <b>{labels?.sponserInfo?.amount?.[lang]}</b> :&nbsp;
              </Typography>
              <Typography as="caption">
                {objCat?.Root?.toSupport?.[state?.lstTrans?.[state?.intEditIndex]?.strTrainingType]?.blnIsTraining?.value?.[lang] === "false"
                  ? state?.lstTrans?.[state?.intEditIndex]?.intTrainingProgPrice
                  : Number(
                      state?.lstTrans?.[state?.intEditIndex]?.intReceivedAmount
                        ? state?.lstTrans?.[state?.intEditIndex]?.intReceivedAmount
                        : state?.lstTrans?.[state?.intEditIndex]?.intTrainingProgPrice * state?.lstTrans?.[state?.intEditIndex]?.intTraineesCount
                    ).toLocaleString()}
                {dictionary?.shared?.app?.dinar?.[lang]}
              </Typography>
            </Grid>
          </Grid>

          <Line my-3 />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                as="subtitle2"
                sx={{
                  borderInlineStart: `5px solid ${App_Primary_Color} !important`,
                  paddingInlineStart: "8px !important",
                  color: App_Primary_Color,
                  fontFamily: "'Hand Font', 'Reem Kufi', sans-serif !important",
                }}
              >
                {labels?.contactInfo?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography as="caption">
                <b>{dictionary?.formapi?.strSponsorName?.label?.[lang]}</b> :&nbsp;
              </Typography>
              <Typography as="caption">{state?.objSelectedPartnerInfo?.jsnPartnerInfo?.strPlaceName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography as="caption">
                <b>{labels?.sponserName?.[lang]}</b> :&nbsp;
              </Typography>
              <Typography as="caption">{state?.objSelectedPartnerInfo?.jsnPartnerInfo?.strFullName}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography as="caption">
                <b>{dictionary?.formapi?.strEmail?.label?.[lang]}</b> :&nbsp;
              </Typography>
              <Typography as="caption">
                <a href={`mailto:${state?.objSelectedPartnerInfo?.jsnPartnerInfo?.strUserEmail}`} target="_blank" rel="noreferrer">
                  {state?.objSelectedPartnerInfo?.jsnPartnerInfo?.strUserEmail}
                </a>
              </Typography>
            </Grid>
            {state?.objSelectedPartnerInfo?.jsnPartnerInfo?.strUserPhone && (
              <Grid item xs={12}>
                <Typography as="caption">
                  <b>{dictionary?.formapi?.strPhone?.label?.[lang]}</b> :&nbsp;
                </Typography>
                <Typography as="caption">
                  <a href={`tel:${state?.objSelectedPartnerInfo?.jsnPartnerInfo?.strUserPhone}`} target="_blank" rel="noreferrer">
                    {state?.objSelectedPartnerInfo?.jsnPartnerInfo?.strUserPhone}
                  </a>
                </Typography>
              </Grid>
            )}
            {state?.lstTrans?.[state?.intEditIndex]?.strNote && (
              <Grid item xs={12} container>
                <Grid item px-0>
                  <Typography as="caption" px-0>
                    <b>{labels?.note?.[lang]}</b> :&nbsp;
                  </Typography>{" "}
                </Grid>
                <Grid item xs="8" sm="9" md="10" style={{ alignSelf: "baseline" }} px-0>
                  <Typography as="caption">{state?.lstTrans?.[state?.intEditIndex]?.strNote}</Typography>
                </Grid>
              </Grid>
            )}
            {!objSystemConfig?.blnIsContact ? null : (
              <Grid item xs="12">
                <FormField objHandler={fields?.strContact} />
              </Grid>
            )}
          </Grid>
          <Line my-3 />
          {state?.lstTrans?.[state?.intEditIndex]?.lstReceipt?.length || fields?.strContact?.getValue()?.[0] ? (
            <React.Fragment>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    as="subtitle2"
                    sx={{
                      borderInlineStart: `5px solid ${App_Primary_Color} !important`,
                      paddingInlineStart: "8px !important",
                      color: App_Primary_Color,
                      fontFamily: "'Hand Font', 'Reem Kufi', sans-serif !important",
                    }}
                  >
                    {labels?.transactionInfo?.[lang]}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                {!state?.lstPaymentVoucher?.[0] ? (
                  <React.Fragment>
                    <Grid item>
                      <Typography as="body2">{labels?.uploadReceiptVoucher2?.[lang]} &nbsp;</Typography>
                    </Grid>
                    <Grid item>{uploaderPayment?.JSX}</Grid>
                  </React.Fragment>
                ) : (
                  <Grid item xs={12} sx={{ alignItems: "center" }}>
                    <Typography as="caption">
                      <b>{labels?.paymentVoucherShow?.[lang]}</b> :{" "}
                    </Typography>
                    <Button
                      size="sm"
                      p-0
                      icon={"download"}
                      disabled={!state?.lstPaymentVoucher?.[0]?.path}
                      onClick={() =>
                        appFunctions.fetchFileFromUrlAndDownload(
                          appVariables.App_Server_Url_DownloadFiles + state?.lstPaymentVoucher?.[0]?.path,
                          state?.lstPaymentVoucher?.[0]?.name
                        )
                      }
                    />
                  </Grid>
                )}
                {!state?.lstPaymentVoucher?.[0] ? null : (
                  <Grid item xs={12}>
                    <Typography as="caption">
                      <b>{dictionary.formapi?.intReceivedAmount?.label?.[lang]}</b> :&nbsp;
                    </Typography>
                    <Typography as="caption">
                      {`${Number(
                        fields?.intTrainingProgPrice?.getValue() || 
                        state?.lstTrans?.[state?.intEditIndex]?.intReceivedAmount ||
                          state?.lstTrans?.[state?.intEditIndex]?.intTraineesCount * state?.lstTrans?.[state?.intEditIndex]?.intTrainingProgPrice
                          || state?.lstTrans?.[state?.intEditIndex]?.intTrainingProgPrice
                      ).toLocaleString()} ${dictionary?.components?.public?.join?.dinar?.[lang]}`}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              {String(state?.lstTrans?.[state?.intEditIndex]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus.Rejected) ? null : String(
                  state?.lstTrans?.[state?.intEditIndex]?.intSupportTransStatus
                ) === String(appVariables.objSupportTransStatus.Approved) ? (
                <Grid container>
                  <Grid item xs={12} sx={{ alignItems: "center" }}>
                    <Typography as="caption">
                      <b>{labels?.receiptVoucherShow?.[lang]}</b> :{" "}
                    </Typography>
                    <Button
                      size="sm"
                      p-0
                      icon={"download"}
                      disabled={!state?.lstReceiptVoucher?.[0]?.path}
                      onClick={() =>
                        appFunctions.fetchFileFromUrlAndDownload(
                          appVariables.App_Server_Url_DownloadFiles + state?.lstReceiptVoucher?.[0]?.path,
                          state?.lstReceiptVoucher?.[0]?.name
                        )
                      }
                    />
                  </Grid>
                  {}
                  <Grid item xs="12">
                    <Typography as="caption">
                      {labels?.amountExpected?.[lang]}
                      {` `}
                      <span style={{ background: App_Light_blue }}>
                        {Number(state?.lstTrans?.[state?.intEditIndex]?.intReceivedAmount).toLocaleString()} {dictionary?.components?.public?.join?.dinar?.[lang]}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Line my-2 />

                  <Grid container mt-4>
                    <Grid item xs={12} mb-3>
                      <Typography as="caption">
                        {String(state.lstTrans?.[state.intEditIndex]?.intSupportTransStatus) === String(appVariables?.objSupportTransStatus.Approved)
                          ? labels.noteChangeStatusReject?.[lang]
                          : String(state.lstTrans?.[state.intEditIndex]?.intSupportTransStatus) === String(appVariables?.objSupportTransStatus.Rejected)
                          ? labels.noteChangeStatusApprove?.[lang]
                          : labels.noteChangeStatus?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item md="3" xs={7} p-0 style={{ alignSelf: "flex-start" }}>
                      <Grid container>
                        <Grid item xs="11" md="auto" p-0>
                          <FormField
                            objHandler={fields.transactionDecision}
                            onChange={() => {
                              if (String(fields.transactionDecision?.getValue()?.key) !== String(appVariables.objSupportTransStatus.Rejected)) {
                                setState({ ...state, blnOpenTransction: true });
                              }
                            }}
                          />
                        </Grid>
                        {String(fields.transactionDecision?.getValue()?.key) !== String(appVariables.objSupportTransStatus.Approved) ? null : (
                          <Grid item xs="1" sx={{ alignSelf: "baseline" }} mt-0>
                            <Icon
                              icon={state?.lstReceiptVoucher?.length ? "check" : "close"}
                              sx={{ border: "1px solid currentColor !important" }}
                              color={state?.lstReceiptVoucher?.length ? "success" : "error"}
                              onClick={() => setState({ ...state, blnOpenTransction: true })}
                            />
                          </Grid>
                        )}
                        {String(fields.transactionDecision?.getValue()?.key) !== String(strTransactionNeedToModify) ? null : (
                          <Grid item xs="1">
                            <Icon
                              sx={{ border: "1px solid currentColor !important" }}
                              icon={
                                state?.lstReceiptVoucher?.length &&
                                fields.trainingNumberAdmin?.getValue() &&
                                fields.strSupportCatType?.getValue() &&
                                fields.intTrainingProgPrice?.getValue()
                                  ? "check"
                                  : "close"
                              }
                              color={
                                state?.lstReceiptVoucher?.length &&
                                fields.trainingNumberAdmin?.getValue() &&
                                fields.strSupportCatType?.getValue() &&
                                fields.intTrainingProgPrice?.getValue()
                                  ? "success"
                                  : "error"
                              }
                              onClick={() => setState({ ...state, blnOpenTransction: true })}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container mt-4 justify={"center"}>
                    <Grid item>
                      {String(fields.transactionDecision?.getValue()?.key) === String(appVariables.objSupportTransStatus.Approved) ? (
                        <Button size="sm" label={labels?.approve?.[lang]} onClick={updateSupportTranToDB(appVariables.objSupportTransStatus.Approved)} color="success" />
                      ) : String(fields.transactionDecision?.getValue()?.key) === String(strTransactionNeedToModify) ? (
                        <Button size="sm" label={labels?.approve?.[lang]} onClick={updateSupportTranToDB(appVariables.objSupportTransStatus.Approved, true)} color="success" />
                      ) : String(fields.transactionDecision?.getValue()?.key) === String(appVariables.objSupportTransStatus.Rejected) ? (
                        <Button size="sm" label={labels?.exclude?.[lang]} onClick={updateSupportTranToDB(appVariables.objSupportTransStatus.Rejected)} color="error" />
                      ) : null}
                    </Grid>
                  </Grid>
                </>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid container>
                <Grid item xs="12">
                  <Typography as="caption">{labels?.noUploadPaymentVoucher?.[lang]}</Typography>
                </Grid>
                <Grid item xs="12">
                  <Typography as="caption">
                    {labels?.amountExpected?.[lang]}
                    {` `}
                    <span style={{ background: App_Light_blue }}>
                      {objCat?.Root?.toSupport?.[state?.lstTrans?.[state?.intEditIndex]?.strTrainingType]?.blnIsTraining?.value?.[lang] === "false"
                        ? state?.lstTrans?.[state?.intEditIndex]?.intTrainingProgPrice
                        : Number(
                            state?.lstTrans?.[state?.intEditIndex]?.intReceivedAmount
                              ? state?.lstTrans?.[state?.intEditIndex]?.intReceivedAmount
                              : state?.lstTrans?.[state?.intEditIndex]?.intTrainingProgPrice * state?.lstTrans?.[state?.intEditIndex]?.intTraineesCount
                          ).toLocaleString()}
                      {dictionary?.shared?.app?.dinar?.[lang]}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt-4 justify={"center"}>
                <Grid item>
                  <Button size="sm" label={labels?.reject?.[lang]} onClick={updateSupportTranToDB(appVariables.objSupportTransStatus.Rejected)} color="error" />
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>

      <Modal size="sm" open={state?.blnOpenTransction} eventClose={() => setState({ ...state, blnOpenTransction: false })}>
        <ModalBody>
          <Grid item xs={12} p-0 style={{ alignSelf: "flex-start" }}>
            {String(fields.transactionDecision?.getValue()?.key) !== String(strTransactionNeedToModify) ? null : (
              <Grid container justify={"center"} spacing={2}>
                <Grid item xs="12">
                  <Typography as="body2">{labels?.enterdata?.[lang]}</Typography>
                </Grid>

               
                <Grid item xs="12">
                  <FormField
                    objHandler={fields.strSupportCatType}
                    endAdornment={
                      strTrainingType === appVariables.objTrainingToSupport?.fullstack?.key
                        ? dictionary?.components?.public?.join?.trainingCustomNumber?.[lang]
                        : dictionary?.components?.public?.join?.trainingCustomNumber?.[lang]
                    }
                    dir={lang === "arb" ? "rtl" : "ltr"}
                    onChange={onChangeCatType}
                  />
                </Grid>
                {objCat?.Root?.toSupport?.[state?.lstTrans?.[state?.intEditIndex]?.strTrainingType]?.blnIsTraining?.value?.[lang] === "false" ? null : (
                  <Grid item xs="12">
                    <FormField
                      objHandler={fields.trainingNumberAdmin}
                      endAdornment={dictionary?.components?.public?.join?.trainingCustomNumber?.[lang]}
                      onChange={onChangeTraneeCount}
                    />
                  </Grid>
                )}

                <Grid item xs="12" sx={{ xs: { padding: "0px !important" } }}>
                  <FormField objHandler={fields.intTrainingProgPrice} endAdornment={labels?.currency?.[lang]} />
                </Grid>
              </Grid>
            )}

            {String(fields.transactionDecision?.getValue()?.key) !== String(appVariables.objSupportTransStatus.Approved) &&
            String(fields.transactionDecision?.getValue()?.key) !== String(strTransactionNeedToModify) ? null : (
              <Grid container justify={"space-between"}>
                <Grid item>
                  <Typography as="body2">{labels?.uploadReceiptVoucher2?.[lang]} &nbsp;</Typography>
                </Grid>
                <Grid item>{uploaderReceipt?.JSX}</Grid>
              </Grid>
            )}
          </Grid>
          {String(fields.transactionDecision?.getValue()?.key) !== String(strTransactionNeedToModify) ? null : (
            <Grid container justify={"center"} spacing={2} mt-5>
              <Grid item>
                <Button label={dictionary?.shared?.btn?.save?.[lang]} size="sm" px-2 onClick={() => setState({ ...state, blnOpenTransction: false })} />
              </Grid>
            </Grid>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}
