import React, { useEffect, useState } from "react";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import { CtrlMngSystem } from "./controler/CtrlMngSystem";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { dictionary, appLanguage } from "../../../../appHelper/appLanguage/dictionary";
import { FormData } from "../../../../appHelper/formApi/FormAPI";
import { Button, Container, Grid, Loader } from "@cbmisorg/client-app";
import { App_Primary_Color } from "../../../../appHelper/appColor";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import { useApp } from "../../../../../client/configuration/contextapi/context";
import TrainingConfig from "./component/TrainingConfig";
import SponerConfig from "./component/SponerConfig";
import ContactConfig from "./component/ContactConfig";
import RegisterConfig from "./component/RegisterConfig";
import ResearcherConfig from "./component/ResearcherConfig";
import ActivityConfig from "./component/ActivityConfig";

const labels = dictionary?.components?.manageSystem;

function ManageSystem() {
  const { getNewSystemInfo } = useCheckSystemInfo();
  const { getNewCatInfo } = useCheckCat();
  const { appState } = useApp();

  const lang = appState?.clientInfo?.strLanguage;
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    systemInfo: null,
    selectedTrainingInfo: null,
    blnOpenModalSponser: false,
    lstVideo: [],
  });

  const fields = FormData({
    lstTrainingToSupport: {
      strControl: "checkbox",
      objLanguage: false,
      lstOption: [],
    },
    blnIsConfiremSponer: {
      strControl: "checkbox",
      objLanguage: false,
      lstOption: [{ key: "true", value: labels?.blnIsConfiremSponer?.[lang] }],
    },
    blnIsPaid: {
      strControl: "radio",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: "hideCard", value: labels?.blnIsHide?.[lang] },
        { key: "contact", value: labels?.blnIsContact?.[lang] },
        { key: "paid", value: labels?.blnIsPaid?.[lang] },
      ],
    },
    strBankInfo: {
      strControl: "textarea",
      objValidations: { required: true },
    },
    lstContryRegister: {
      strControl: "multiplelist",
      objLanguage: false,
      lstOption: [],
    },
    strDefaultContryRegister: {
      strControl: "list",
      objLanguage: false,
      lstOption: [],
    },
    lstContrySponser: {
      strControl: "multiplelist",
      objLanguage: false,
      lstOption: [],
    },
    lstContryResearcher: {
      strControl: "multiplelist",
      objLanguage: false,
      lstOption: [],
    },
    lstContryActivity: {
      strControl: "multiplelist",
      objLanguage: false,
      lstOption: [],
    },
    lstContryDetailsRegister: {
      strControl: "multiplelist",
      objLanguage: false,
      lstOption: [],
    },
    lstContryResearcherReqVideo: {
      strControl: "multiplelist",
      objLanguage: false,
      lstOption: [],
    },
    lstContryDetailsAccount: {
      strControl: "multiplelist",
      objLanguage: false,
      lstOption: [],
    },
    strDetailsAAiotRegister: {
      strControl: "textArea",
      objLanguage: true,
      lstOption: [],
    },
    strDetailsAAiotDefaultContryRegister: {
      strControl: "textArea",
      objLanguage: true,
      lstOption: [],
    },
    strDetailsAAiotRegisterLink: {
      strControl: "text",
      objLanguage: true,
      lstOption: [],
    },
    strDetailsAAiotAccount: {
      strControl: "textArea",
      objLanguage: true,
      lstOption: [],
    },
    strDetailsAAiotDefaultContryAccount: {
      strControl: "textArea",
      objLanguage: true,
      lstOption: [],
    },
    strDetailsAAiotAccountLink: {
      strControl: "text",
      objLanguage: true,
      lstOption: [],
    },
  });
  const fieldTraining = FormData({
    lstSponser: {
      strControl: "checkbox",
      objLanguage: false,
      lstOption: [],
    },
    strVedioTitle: {
      strControl: "text",
      objLanguage: appLanguage,
    },
    lstCountryTraining: {
      strControl: "multiplelist",
      objLanguage: false,
      lstOption: [],
    },
    lstContryTrainingReqVideo: {
      strControl: "multiplelist",
      objLanguage: false,
      lstOption: [],
    },
    blnShowOptionInAdminAccount: {
      strControl: "checkbox",
      objLanguage: false,
      lstOption: [{ key: "true", value: dictionary.formapi?.blnShowManagerTrainingOption?.options?.true?.[lang] }],
    },
  });

  const fieldsContact = FormData({
    strPhoneNumber: {
      strControl: "text",
      objLanguage: false,
    },
    strEmail: {
      strControl: "text",
      objLanguage: false,
    },
    strSupportEmail: {
      strControl: "text",
      objLanguage: false,
    },
    strSupportPhone: {
      strControl: "text",
      objLanguage: false,
    },
    strXLink: {
      strControl: "text",
      objLanguage: false,
    },
    strFacebookLink: {
      strControl: "text",
      objLanguage: false,
    },
    strInstgramLink: {
      strControl: "text",
      objLanguage: false,
    },
    strLinkedInLink: {
      strControl: "text",
      objLanguage: false,
    },
    strAddress: {
      strControl: "textarea",
    },

    strFormEmail: {
      strControl: "text",
    },
  });

  const handlers = CtrlMngSystem(appState, isLoading, setIsLoading, stateInfo, setStateInfo);
  const handleSubmit = handlers.handleSubmit({ fields, fieldsContact });

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      handlers.getSystemInfo({ fields, fieldTraining, fieldsContact, getNewSystemInfo, checkCatHookValidity: getNewCatInfo });
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false} />
      <Container py-3>
        {isLoading ? <Loader backdrop={true} color={App_Primary_Color} /> : null}
        <RegisterConfig appState={appState} fields={fields} />
        <TrainingConfig appState={appState} fieldTraining={fieldTraining} fields={fields} handlers={handlers} setStateInfo={setStateInfo} stateInfo={stateInfo} />
        <SponerConfig appState={appState} fields={fields} stateInfo={stateInfo} />
        <ResearcherConfig appState={appState} fields={fields} />
        <ActivityConfig appState={appState} fields={fields} />
        <ContactConfig appState={appState} fieldsContact={fieldsContact} />

        
        <Grid container spacing={2} justify={"center"}>
          <Grid item xs="10" sm="4" md="3">
            <Button color="primary" size="sm" label={dictionary?.shared?.btn?.save?.[lang]} sx={{ minWidth: "100%" }} onClick={handleSubmit} />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default ManageSystem;
