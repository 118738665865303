import React, {  useEffect, useState } from "react";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { Badge, Container, Grid, Icon, Typography, useNavigate, Box, Alert, useParams } from "@cbmisorg/client-app";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { decompressText, getAppLocalStorage, getNextStrURL, logMessage } from "../../../../appHelper/appFunctions";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";
import { generatePath } from "react-router-dom";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { objTrainingToSupport } from "../../../../appHelper/appVariables";
import { useApp } from "../../../../../client/configuration/contextapi/context";

const strPageInfo = "@/src/components/stakeholders/admin/account/Account.js";
const tblStatistic = generateQueries("tblStatistic");
const objCat = decompressText(getAppLocalStorage()?.clientInfo?.objCatInfo);

function RouteTraining() {
  useCheckCat();
  const { strTrainingType } = useParams();

  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();
  // useCheckUserLoginDevice()
  const { getNewSystemInfo } = useCheckSystemInfo({ notHook: true, isGetCount: true });
  const lang = appState?.clientInfo?.strLanguage;
  const lstUserFeature = appState?.userInfo?.lstUserFeature || [];
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    trainingNav:[]
  });

  // let trainingNav = [
  //   {
  //     icon: "ModelTraining",
  //     label: dictionary?.components?.mnageRequestJoin?.title,
  //     url: generatePath(objAppRouting?.manage_Taining_info?.url, { strTrainingType: strTrainingType }),
  //     featureID: objAppRouting?.manage_Taining_info?.featureID,
  //     badge: strTrainingType === objTrainingToSupport.manager.key ? "intCountJoinManager" : "intCountJoinStudent",
  //   },
  //   {
  //     icon: "group",
  //     label: dictionary?.components?.managePartner?.title,
  //     url: generatePath(objAppRouting?.Manage_Partner?.url, { strTrainingType: strTrainingType }),
  //     featureID: objAppRouting?.Manage_Partner?.featureID,
  //     badge: strTrainingType === objTrainingToSupport.manager.key ? "intCountJoinPartnerManager" : "intCountJoinPartnerFullstack",
  //   },
  //   {
  //     icon: "accountBalance",
  //     label: dictionary?.components?.mngSupportTrans?.title,
  //     url: generatePath(objAppRouting?.Manage_Partner_Support_Trans?.url, { strTrainingType: strTrainingType }),
  //     featureID: objAppRouting?.Manage_Partner_Support_Trans?.featureID,
  //     badge: strTrainingType === objTrainingToSupport.manager.key ? "intCountTransPartnerManager" : "intCountTransPartnerFullstack",
  //   },
  //   {
  //     icon: "uploadFile",
  //     label: dictionary?.components?.mngPotentialSponsorship?.title,
  //     url: generatePath(objAppRouting?.Mng_Potential_Partner?.url, { strTrainingType: strTrainingType }),
  //     featureID: objAppRouting?.Mng_Potential_Partner?.featureID,
  //   },
  // ];
  

  useEffect(() => {
    if(objCat?.Root?.toSupport?.[strTrainingType]?.blnIsTraining?.value?.eng === "false"){
      state.trainingNav=[
        {
          icon: "autoAwesomeOutlined",
          label: dictionary?.components?.registeredActivities?.title,
          url: objAppRouting?.Mng_Registered_Activities?.url,
          featureID: objAppRouting?.Mng_Registered_Activities?.featureID,
          badge:"intCountActivityGuest"
        },
        {
          icon: "accountBalance",
          label: dictionary?.components?.mngSupportTrans?.title,
          url: generatePath(objAppRouting?.Manage_Partner_Support_Trans?.url, { strTrainingType: strTrainingType }),
          featureID: objAppRouting?.Manage_Partner_Support_Trans?.featureID,
          badge:"intCountTransPartnerManager",
        },
      ]
    }else{
      state.trainingNav=[
        {
          icon: "ModelTraining",
          label: dictionary?.components?.mnageRequestJoin?.title,
          url: generatePath(objAppRouting?.manage_Taining_info?.url, { strTrainingType: strTrainingType }),
          featureID: objAppRouting?.manage_Taining_info?.featureID,
          badge: "intCountJoinStudent",
        },
        {
          icon: "accountBalance",
          label: dictionary?.components?.mngSupportTrans?.title,
          url: generatePath(objAppRouting?.Manage_Partner_Support_Trans?.url, { strTrainingType: strTrainingType }),
          featureID: objAppRouting?.Manage_Partner_Support_Trans?.featureID,
          badge: "intCountTransPartnerFullstack",
        },
        {
          icon: "uploadFile",
          label: dictionary?.components?.mngPotentialSponsorship?.title,
          url: generatePath(objAppRouting?.Mng_Potential_Partner?.url, { strTrainingType: strTrainingType }),
          featureID: objAppRouting?.Mng_Potential_Partner?.featureID,
        },
      ]
    }
    setState({...state})
    const accountUrl = getNextStrURL();
    if (accountUrl !== objAppRouting?.Account?.url) {
      navigate(accountUrl, { replace: true });
    }
    getNewSystemInfo(appDispatch);
  }, []);

  const functionClickedNav = (item) => async () => {
    navigate(item.url);
    if (!item?.badge) {
      return;
    }
    if (isLoading) {
      Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
      return;
    }

    setIsLoading(true);
    const result = await tblStatistic(strPageInfo, appState, "aaiotAppUpdate", {
      objectInput: {
        [item?.badge]: 0,
        dtmUpdatedDate: new Date().toUTCString(),
      },
      objectCondition: {
        bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appState?.userInfo.bigSystemID,
      },
    });
    if (!result?.blnIsRequestSuccessful) {
      logMessage(result?.response, "");
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <ShredHeader title={objCat?.Root?.toSupport?.[strTrainingType]?.value?.[lang]} lang={lang} blnHome={true} blnLang={false} />
      <Container py-10>
        <Grid container justify={"center"} spacing={1}>
          {state?.trainingNav
            ?.filter((item) => lstUserFeature?.includes(item?.featureID) || !item?.featureID)
            ?.map((item, index) => {
              return (
                <Grid item xs="6" sm="4" md="3" lg="2" key={index} className="navAccount">
                  <Box className="boxNav" onClick={functionClickedNav(item)}>
                    <Grid container justify={"center"}>
                      <Grid item xs="12">
                        {item?.badge && appState?.clientInfo?.objSystemInfo?.[item?.badge] ? (
                          <Badge content={appState?.clientInfo?.objSystemInfo?.[item?.badge]}>
                            <Icon icon={item?.icon} color="primary" />
                          </Badge>
                        ) : (
                          <Icon icon={item?.icon} color="primary" />
                        )}
                      </Grid>
                      <Grid item xs="12">
                        <Typography as="caption" className="textcenter">
                          {item?.label?.[lang]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default RouteTraining;
