import * as appPages from "./appPages";


const appRouting = {
  landingPage: { url: appPages?.landingPage, featureID: 8786507229 },

  Public_Login: { url: appPages?.login, featureID: 2580267594 },
  Public_Initiative: { url: appPages?.initiative, featureID: 0 },

  Reg_Activity_Guest: { url: appPages?.regActivityGuest, featureID: 8786507229 },
  Register: { url: appPages?.register, featureID: 5094561645 },
  Registration: { url: appPages?.registration, featureID: 5094561645 },
  restPassword: { url: appPages?.restPassword, featureID: 1446339286 },
  page404: { url: appPages?.page404, featureID: 0 },
  media: { url: appPages?.media, featureID: 0 },

  Account: { url: appPages?.account, featureID: 6759118668 , featureAuth: true},
  Profile: { url: appPages.profile, featureID: 3195608470 , featureAuth: false},
  SponsorProfile: { url: appPages.supportinfo, featureID: 6759118668 , featureAuth: false},
  
  Generate_Certificate: { url: appPages.Generate_Certificate, featureID: 8898633684 , featureAuth: false},
  ECertificate: { url: appPages.ECertificate, featureID: 8898633684 , featureAuth: false},



};
export default appRouting;