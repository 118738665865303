"use client";
import React, { useEffect } from "react";
import { useForm, FormField as ViewFormField, useFormType, FormFieldType } from "@cbmisorg/form-api";
import { appLanguage, dictionary } from "../appLanguage/dictionary";
import { useApp } from "../../configuration/contextapi/context";

/**
 * @type {useFormType}
 */
export function FormData(objFields) {
  const { appState } = useApp();

  const objDefaultLabels = {
    currentLang: appState?.clientInfo?.strLanguage,
    defaultLang: "eng",
    blnDuplicateLangValue: true,
    blnDuplicateLangValueOnType: true,
    objLang: appLanguage,
    labels: dictionary?.formapi,
    // defaultValidationsMsg: dictionary?.formValidationsMsg,
  };
  const FormHook = useForm(objFields || {}, objDefaultLabels);

  useEffect(() => {
    FormHook.controller.setFormLanguage(appState?.clientInfo?.strLanguage);
  }, [appState?.clientInfo?.strLanguage]);

  return FormHook;
}

/**
 *
 * @type { FormFieldType }
 */
export const FormField = ({ ...rest }) => {
  return <ViewFormField objHandler={rest?.objHandler} {...rest}  />;
};
