
//#region public
export const landingPage = "/";
export const sheikProfile= "/sheikProfile";
export const login = "/login";
export const restPassword = "/restPassword"

export const regActivityGuest = "/:activityCode";
export const register = "/register";
export const registration = "/register/:countryCode";
export const page404 = "/404";
export const media = "/:strUrlVedio"

export const initiative = "/training/:strTrainingType";
export const Generate_Certificate="/generatecertificate";
export const ECertificate="/ecertificate/:id";


//#endregion 

export const account= "/account";
export const supportinfo="/supportinfo";
export const profile="/profile";
