import { Alert } from "@cbmisorg/material";
import * as appVariables from "../../../../configuration/appVariables";
import * as appFunctions from "../../../../util/appFunctions";
import { appLangDirection, dictionary } from "../../../../util/appLanguage/dictionary";
import { generateQueries, objTableAttributes } from "../../../../graphql/schema/GenerateQuery";
import appRouting from "../../../../configuration/routing/appRouting";
import { ClientDirection } from "@cbmisorg/helper";
import { Confirmation } from "@cbmisorg/client-app";
import { alertStyle } from "../../../../util/appStyle";
const userApi = generateQueries("tblUser");
const statisticApi = generateQueries("tblStatistic");

const strPageInfo = "@src/client/component/stakeholders/member/account/Account.controller.js";

export default function accountCtrl({ appState, appDispatch }) {
  const lang = appState?.clientInfo?.strLanguage;

  const handler = {
    initData:
      ({ getNewCatInfo,getNewSystemInfo, state, setState, isLoading, setIsLoading }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          state.blnIsInitDone = true;
          setIsLoading(true);
        
          // if (getNewCatInfo && (String(appState.clientInfo.objCatInfo)?.length < 500 || !appState.clientInfo.objCatInfo )) {
            const newCatInfo = await getNewCatInfo();
            const objCatInfo = appFunctions.compressText(newCatInfo);
            appState.clientInfo.objCatInfo = objCatInfo;
            appState.clientInfo.dtmLastCheckedCatInfo = appFunctions.getCurrentDate();
          // }
          if(getNewSystemInfo){
            const newSystemInfo = await getNewSystemInfo(appDispatch);            
            appState.clientInfo.objSystemInfo = newSystemInfo;
          }

          const result = await userApi(strPageInfo, appState, "aaiotAppInitUserAccount", {
            arrAttributes: objTableAttributes.tblActivityGuest.full,
            objectCondition: {
              bigUserID: appState?.userInfo?.bigUserID,
              bigSystemID: appState?.userInfo?.bigSystemID,
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            setIsLoading(false);
            Alert.viewAlert(dictionary.shared.alertFetchNote.cantFindRecords?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }

          let lstUserRegActivityTmp = Array.isArray(result?.response?.lstActivityGuestInfo) ? result?.response?.lstActivityGuestInfo : [];

          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region sort the activities from category
          // lstRunningActivity contains only ongoing activities (not finished) + its sorted from old to new
          // lstRunningActivity.lstActiveSubjectOrdered contains only on going subject (not finished) + its sorted from old to new + have structure : { subjectKey, subjectValue}
          //lstRunningActivity.lstActiveSubjectOrdered.subjectValue + lstRunningActivity.objActivity : have same structure as category

          const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

          let lstRunningActivity = [];

          Object.entries(objCat?.Root?.activity || {}).forEach(([activityKey, activityValue]) => {
            let dtmMaxDate = null;
            let lstSubject = [];
            if (activityKey !== "id" && activityKey !== "value") {
              Object.entries(activityValue?.subject || {}).forEach(([subjectKey, subjectValue]) => {
                if (subjectKey !== "id" && subjectKey !== "value") {
                  if (!dtmMaxDate) {
                    dtmMaxDate = subjectValue?.date?.value?.eng;
                  } else {
                    if (appFunctions.getDifferenceBetweenTwoDate(new Date(dtmMaxDate).toUTCString(), new Date(subjectValue?.date?.value?.eng).toUTCString(), "day") > 0) {
                      dtmMaxDate = subjectValue?.date?.value?.eng;
                    }
                  }

                  if (appFunctions.getDifferenceBetweenTwoDate(new Date().toUTCString(), new Date(subjectValue?.date?.value?.eng).toUTCString(), "day") >= 0) {
                    lstSubject.push({ subjectKey: subjectKey, subjectValue: subjectValue });
                  }
                }
              });
            }

            if (dtmMaxDate && appFunctions.getDifferenceBetweenTwoDate(new Date().toUTCString(), new Date(dtmMaxDate).toUTCString(), "day") >= 0) {
              lstSubject.sort((a, b) => new Date(a?.date?.value?.eng).getTime() - new Date(b?.date?.value?.eng).getTime());
              lstRunningActivity.push({
                strCatName: activityKey,
                objActivity: activityValue,
                lstActiveSubjectOrdered: lstSubject,
                dtmLastDate: dtmMaxDate,
              });
            }
          });

          lstRunningActivity.sort((a, b) => new Date(a?.dtmLastDate).getTime() - new Date(b?.dtmLastDate).getTime());
          //#endregion
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region check if registration user activities is ongoing (not finished)
          let lstUserRegActivity = [];
          for (let i = 0; i < lstUserRegActivityTmp.length; i++) {
            for (let j = 0; j < lstRunningActivity.length; j++) {
              if (String(lstUserRegActivityTmp?.[i]?.bigActivityType) === String(lstRunningActivity?.[j]?.objActivity?.id)) {
                lstUserRegActivity.push(lstUserRegActivityTmp[i]);
                break;
              }
            }
          }
          //#endregion

          state.lstRunningActivity = lstRunningActivity;
          state.lstUserRegActivity = lstUserRegActivity;
          state.objPartnerInfo = result?.response?.partnerInfo;


          
          

          delete objCat?.Root?.services?.id;
          delete objCat?.Root?.services?.value;
          const lstServices = [];
          Object.keys(objCat?.Root?.services || {})?.forEach((key) => {
            const service = objCat?.Root?.services[key];
            const lstRoleFlag = appFunctions.checkParseObject(service?.lstRoleFlag?.value?.[lang]);
            if (appState?.userInfo?.blnIsManager || appState?.userInfo?.blnIsSponsor || appState?.userInfo?.blnIsStartUp || appState?.userInfo?.blnIsStudent) {
              for (let index = 0; index < lstRoleFlag.length; index++) {
                const roleFlag = lstRoleFlag[index];
                if (appState?.userInfo?.[roleFlag] === true) {
                  lstServices.push(service);
                  return;
                }
              }
            } else if (!(lstRoleFlag?.length === 1 && lstRoleFlag?.[0] === "blnIsSponsor")) {
              lstServices.push(service);
            }
          });

          state.lstServices = lstServices;
          setState({ ...state });
          setIsLoading(false);
          appDispatch();
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "initData-ERROR", error);
        }
      },

    // getPartnerInfo:
    //   ({ state, setState, isLoading, setIsLoading }) =>
    //   async () => {
    //     try {
    //       if (isLoading) {
    //         Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
    //         return;
    //       }

    //       setIsLoading(true);

    //       const result = await partnerApi(strPageInfo, appState, "aaiotAppFindOne", {
    //         arrAttributes: objTableAttributes.tblPartner.full,
    //         objectCondition: {
    //           bigPartnerID: appState?.userInfo?.bigUserID,
    //         },
    //       });

    //       if (!result?.blnIsRequestSuccessful) {
    //         setIsLoading(false);
    //         Alert.viewAlert(dictionary.shared.alertFetchNote.cantFindRecords?.[lang], "warning", appLangDirection?.[lang], alertStyle);
    //         return;
    //       }

    //       if (result?.response && result?.response?.bigPartnerID && !Number.isNaN(Number(result?.response?.bigPartnerID))) {
    //         state.objPartnerInfo = result?.response?.partnerInfo;
    //       } else {
    //         state.objPartnerInfo = null;
    //       }

    //       setState({ ...state });
    //       setIsLoading(false);
    //     } catch (error) {
    //       setIsLoading(false);
    //       appFunctions.logError(strPageInfo, "getPartnerInfo-ERROR", error);
    //     }
    //   },

    flipUserType:
      ({ state, setState, isLoading, setIsLoading }) =>
      async (userType, blnIsEnable) => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }

          setIsLoading(true);

          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update user
          let objInput = {};
          if (userType === "blnIsManager") {
            objInput = { blnIsManager: blnIsEnable ? false : true };
          }
          if (userType === "blnIsStudent") {
            objInput = { blnIsStudent: blnIsEnable ? false : true };
          }
          if (userType === "blnIsStartUp") {
            objInput = { blnIsStartUp: blnIsEnable ? false : true };
          }
          if (userType === "blnIsSponsor") {
            objInput = { blnIsSponsor: blnIsEnable ? false : true };
          }

          const resultUser = await userApi(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectCondition: {
              bigUserID: appState?.userInfo?.bigUserID,
            },
            objectInput: {
              ...objInput,
              dtmUpdatedDate: new Date(),
            },
          });

          if (!resultUser?.blnIsRequestSuccessful) {
            setIsLoading(false);
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Update_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }

          appState.userInfo = {
            ...appState.userInfo,
            ...objInput,
          };

          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region statistics update
          let lstStatistics = [];
          if (userType === "blnIsManager") {
            lstStatistics = ["intCountJoinManagerAll"];
          }
          if (userType === "blnIsStudent") {
            lstStatistics = ["intCountJoinStudentAll"];
          }
          if (userType === "blnIsStartUp") {
            lstStatistics = ["intCountJoinEntrepreneurAll"];
          }
          if (userType === "blnIsSponsor") {
            lstStatistics = ["intCountJoinSponsorAll"];
          }

          if (lstStatistics?.length) {
            await statisticApi(strPageInfo, appState, "aaiotAppIncDecInt", {
              objectCondition: {
                bigSystemID: appVariables.aaiot_System_ID,
                operation: blnIsEnable ? "dec" : "inc",
              },
              arrAttributes: lstStatistics,
            });
          }
          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          appDispatch();
          setState({ ...state });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "flipUserType-ERROR", error);
        }
      },
    funLogout:
      ({ navigate, appState }) =>
      () => {
        Confirmation.viewConfirmation(
          dictionary?.shared?.confirmationMsg?.logout?.[lang],
          () => {
            appFunctions.clearAppLocalStorage(appState);
            navigate(appRouting?.landingPage?.url, { replace: true });
          },
          () => {},
          dictionary?.shared?.confirmationMsg?.yesSure?.[lang],
          dictionary?.shared?.confirmationMsg?.no?.[lang]
        );
      },
    handelChangeLang: () => {
      if (lang === "arb") {
        ClientDirection.setDirection("ltr");
        appState.clientInfo.strLanguage = "eng";
        appState.clientInfo.strDataLanguage = "eng";
      } else {
        ClientDirection.setDirection("rtl");
        appState.clientInfo.strLanguage = "arb";
        appState.clientInfo.strDataLanguage = "arb";
      }
      appDispatch();
    },
  };

  return handler;
}

/* trainee
    jsnUserInfo: {
                strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
                bigCountryOfCitizenID: fields?.bigCountryOfCitizenID?.getValue()?.key,
                bigCountryOfResidenceID: fields?.bigCountryOfResidenceID?.getValue()?.key,
                strMajor: fields?.bigMajorID?.getValue()?.key,
                bigEmploymentID: fields?.bigEmploymentID?.getValue()?.key,
                bigDegreeID: fields?.bigDegreeID?.getValue()?.key,
                bigGender: fields?.bigGender?.getValue()?.key,
                bigAcademicYearID: fields?.bigAcademicYearID?.getValue()?.key,
                strUniversityName: fields?.strUniversityName?.getValue()?.key,
                bigGpaID: fields?.bigGpaID?.getValue()?.key,

                intUserRoleFK: objUserRole?.Student,
              },
*/

/* manager

*/
