import React, {  useEffect, useState } from "react";
import { Button, Container, Grid, Loader, ManageCategories, Paper, useNavigate } from "@cbmisorg/client-app";
import { App_Primary_Color, App_Second_Color } from "../../../../appHelper/appColor";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import { controller } from "./controller/CrtlMngCategory";
import * as appVariables from "../../../../appHelper/appVariables";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { useApp } from "../../../../../client/configuration/contextapi/context";

const objOperation = {
  add: "add",
  edit: "edit",
};
const labels = dictionary?.components?.manageCategories;
export default function RouteMngCategory() {
  const { appState, appDispatch } = useApp();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const lang = appState?.clientInfo?.strLanguage;
  const [state, setState] = useState({
    lstCategory: [],
    strOperationType: null,
    bigCurrentID: null,
    blnOpenSaveDialog: false,
    blnIsInitDone: false,
    lstCatFile: [],
  });

  const fields = FormData({
    bigCatID: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true },
    },

    bigCatParentID: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
    },

    strCatName: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },

    jsnValue: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: { eng: { blnActive: true, blnIsMandatory: true, label: "English" }, arb: { blnActive: true, blnIsMandatory: true, label: "عربي" } },
    },
  });

  const handlers = controller(appState, appDispatch, isLoading, setIsLoading, state, setState, navigate);

  useEffect(() => {
    if (!state.blnIsInitDone) {
      handlers.getAllCategory({ fields });
    }
  }, []);

  const FormJxs = (
    <React.Fragment>
      <Grid container>
        <Grid item xs={6}>
          <FormField objHandler={fields.bigCatID} readOnly={true} icon="appRegistration" iconColor={App_Second_Color} />
        </Grid>
        <Grid item xs={6}>
          <FormField objHandler={fields.bigCatParentID} icon="wrapText" iconColor={App_Second_Color} />
        </Grid>
        <Grid item xs={12}>
          <FormField objHandler={fields.strCatName} icon="category" iconColor={App_Second_Color} />
        </Grid>
        <Grid item xs={12}>
          <FormField objHandler={fields.jsnValue} icon="topicOutlined" iconColor={App_Second_Color} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs="12">
          <Button label="Save" onClick={state.strOperationType === objOperation.add ? handlers.addCategory({ fields }) : handlers.editCategory({ fields })} />
        </Grid>
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false} />
      <Container py-3>
        {!isLoading ? null : <Loader color={App_Second_Color} backdrop={true} />}

        <Grid container mt-3>
          <Grid item xs={12} dir="ltr">
            <Paper>
              <ManageCategories
                list={state.lstCategory}
                rootID={appVariables.Cat_Root_ID}
                objFelidName={{ intID: "bigID", strName: "strName", intParentID: "bigParentID" }}
                blnAddRoot={true}
                blnAddUnderTheRoot={true}
                blnEditRoot={true}
                blnDeleteRoot={false}
                blnSearch={true}
                blnAdd={true}
                blnEdit={true}
                blnDelete={false}
                handleOpenDialog={(event, typeOperation, objCatInfo, bigParentID) => {
                  if (typeOperation === objOperation.add) {
                    fields.bigCatID.setValue("New Category");
                    fields.bigCatParentID.setValue(bigParentID);
                    state.bigCurrentID = null;
                  } else {
                    fields.bigCatID.setValue(objCatInfo?.bigID);
                    fields.bigCatParentID.setValue(objCatInfo?.bigParentID);
                    fields.strCatName.setValue(objCatInfo?.strName);
                    fields.jsnValue.setValue(objCatInfo?.jsnValue);
                    state.bigCurrentID = objCatInfo?.bigID;
                  }
                  setState({ ...state, blnOpenSaveDialog: true, strOperationType: typeOperation });
                }}
                blnOpenDialog={state.blnOpenSaveDialog}
                FormComponent={FormJxs}
                color={App_Primary_Color}
                objLanguage={dictionary}
                currentLanguage={lang}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
