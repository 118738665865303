import { Alert, Grid, Icon, Typography } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries } from "../../../../../appHelper/appQuery/GenerateQuery";
import { aaiot_System_ID } from "../../../../../appHelper/appVariables";
import { App_Gray_Color, App_Primary_Color } from "../../../../../appHelper/appColor";

const strPageInfo = "@src/component/public/manageSystrm/view/contact/controler/CtrlMngSystem.js";

const tblSystem = generateQueries("tblSystem");

export const CtrlMngSystem = (appState, isLoading, setIsLoading, stateInfo, setStateInfo, navigate = null) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID;

  let handler = {
    getSystemInfo: async ({ fields, fieldTraining, fieldsContact, getNewSystemInfo, checkCatHookValidity }) => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }

        
        setIsLoading(true);
        const result = await getNewSystemInfo();
        await checkCatHookValidity();

        let objSystemInfo = result;
        const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

        let lstTrainingOption = [];
        let objTrainingOptionLang = {};

        Object.entries(objCat?.Root?.toSupport || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value" && value?.blnIsTraining?.value?.eng !=="false") {
            lstTrainingOption.push({
              key: key,
              value: key,
              jxs: () => (
                <Grid container justify={"space-between"}>
                  <Grid item>
                    <Typography as="caption">{value?.value?.[lang] + " - " + dictionary?.components?.manageSystem?.price?.[lang] + value?.price?.value?.[lang]}</Typography>
                  </Grid>
                  <Grid item className="app-point">
                    <Icon
                      icon="edit"
                      color={fields?.lstTrainingToSupport?.getValue()?.findIndex((item) => item?.key === key) > -1 ? App_Primary_Color : App_Gray_Color}
                      onClick={handler?.handleOpenSponserModal({ fieldTraining, stateInfo, setStateInfo, selectedTrainingCat: { ...value, key: key }, objSystemInfo })}
                    />
                  </Grid>
                </Grid>
              ),
            });
            objTrainingOptionLang[key] = value?.value;
          }
        });

        let lstContryOption = [];
        let objContryOptionLang = {};

        Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {          
            lstContryOption.push({
              key:value?.code?.value?.eng || value?.id,
              value: value?.id,
            });
            objContryOptionLang[value?.id] = value?.value;

          }
        });

        fields.lstTrainingToSupport.setOptions(lstTrainingOption, objTrainingOptionLang);
        fields.lstTrainingToSupport.setValue(objSystemInfo?.jsnSystemConfig?.lstTrainingToSupport);

        fields.strBankInfo?.setValue(objSystemInfo?.jsnSystemConfig?.strBankInfo);
        fields.blnIsPaid?.setValue(objSystemInfo?.jsnSystemConfig?.blnIsPaid ? "paid" :objSystemInfo?.jsnSystemConfig?.blnIsContact? "contact": "hide");
        fields.blnIsConfiremSponer?.setValue(objSystemInfo?.jsnSystemConfig?.blnIsConfiremSponer ? ["true"] : []);
        


        fieldsContact.strPhoneNumber?.setValue(objSystemInfo?.jsnContact?.strPhoneNumber);
        fieldsContact.strEmail?.setValue(objSystemInfo?.jsnContact?.strEmail);
        fieldsContact.strSupportEmail?.setValue(objSystemInfo?.jsnContact?.strSupportEmail);
        fieldsContact.strSupportPhone?.setValue(objSystemInfo?.jsnContact?.strSupportPhone);
        fieldsContact.strXLink?.setValue(objSystemInfo?.jsnContact?.strXLink);
        fieldsContact.strFacebookLink?.setValue(objSystemInfo?.jsnContact?.strFacebookLink);
        fieldsContact.strInstgramLink?.setValue(objSystemInfo?.jsnContact?.strInstgramLink);
        fieldsContact.strLinkedInLink?.setValue(objSystemInfo?.jsnContact?.strLinkedInLink);
        fieldsContact.strAddress?.setValue(objSystemInfo?.jsnContact?.strAddress);
        fieldsContact.strFormEmail?.setValue(objSystemInfo?.jsnContact?.strFormEmail);

        fieldTraining.lstCountryTraining.setOptions(lstContryOption, objContryOptionLang);
        fieldTraining.lstContryTrainingReqVideo.setOptions(lstContryOption, objContryOptionLang);
        fields.lstContrySponser.setOptions(lstContryOption, objContryOptionLang);
        fields.lstContryResearcher.setOptions(lstContryOption, objContryOptionLang);
        fields.lstContryRegister.setOptions(lstContryOption, objContryOptionLang);
        fields.lstContryDetailsRegister.setOptions(lstContryOption, objContryOptionLang);
        fields.strDefaultContryRegister.setOptions(lstContryOption, objContryOptionLang);
        fields.lstContryActivity.setOptions(lstContryOption, objContryOptionLang);
        fields.lstContryDetailsAccount.setOptions(lstContryOption, objContryOptionLang);
        fields.lstContryResearcherReqVideo.setOptions(lstContryOption, objContryOptionLang);


        fields.lstContrySponser.setValue(objSystemInfo?.jsnSystemConfig?.lstContrySponserCode ||  []);
        fields.lstContryResearcher.setValue(objSystemInfo?.jsnSystemConfig?.lstContryResearcherCode ||  []);
        fields.lstContryRegister.setValue(objSystemInfo?.jsnSystemConfig?.lstContryRegisterCode ||  []);
        fields.lstContryDetailsRegister.setValue(objSystemInfo?.jsnSystemConfig?.lstContryDetailsRegisterCode ||  []);
        fields.strDefaultContryRegister.setValue(objSystemInfo?.jsnSystemConfig?.strDefaultContryRegisterCode ||  "");
        fields.lstContryActivity.setValue(objSystemInfo?.jsnSystemConfig?.lstContryActivityCode ||  []);
        fields.lstContryDetailsAccount.setValue(objSystemInfo?.jsnSystemConfig?.lstContryDetailsAccountCode ||  []);
        fields.lstContryResearcherReqVideo.setValue(objSystemInfo?.jsnSystemConfig?.lstContryResearcherReqVideoCode ||  []);

        fields.strDetailsAAiotRegister.setValue(objSystemInfo?.jsnSystemConfig?.strDetailsAAiotRegister ||  "");
        fields.strDetailsAAiotDefaultContryRegister.setValue(objSystemInfo?.jsnSystemConfig?.strDetailsAAiotDefaultContryRegister ||  "");
        fields.strDetailsAAiotRegisterLink.setValue(objSystemInfo?.jsnSystemConfig?.strDetailsAAiotRegisterLink ||  "");
        fields.strDetailsAAiotAccount.setValue(objSystemInfo?.jsnSystemConfig?.strDetailsAAiotAccount ||  "");
        fields.strDetailsAAiotDefaultContryAccount.setValue(objSystemInfo?.jsnSystemConfig?.strDetailsAAiotDefaultContryAccount ||  "");
        fields.strDetailsAAiotAccountLink.setValue(objSystemInfo?.jsnSystemConfig?.strDetailsAAiotAccountLink ||  "");

        stateInfo.blnIsInitDone = true;
        stateInfo.systemInfo = objSystemInfo;
        setStateInfo({ ...stateInfo });
        setIsLoading(false);
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getNewSystemInfo-ERROR", error);
      }
    },
    handleOpenSponserModal:
      ({ fieldTraining, stateInfo, setStateInfo, selectedTrainingCat, objSystemInfo }) =>
      () => {
        try {
          let lstSponserOption = [];
          let objSponserOptionLang = {};

          Object.entries(selectedTrainingCat?.sponser || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstSponserOption.push({
                key: value?.id,
                value: value?.id,
              });
              objSponserOptionLang[value?.id] = {
                eng: value?.value?.["eng"] + " - " + dictionary?.components?.manageSystem?.studentNumber?.eng + value?.studentNumber?.value?.eng,
                arb: value?.value?.["arb"] + " - " + dictionary?.components?.manageSystem?.studentNumber?.arb + value?.studentNumber?.value?.arb,
              };
            }
          });
          fieldTraining?.lstSponser?.setOptions(lstSponserOption, objSponserOptionLang);
          fieldTraining.lstSponser?.setValue(objSystemInfo?.jsnSystemConfig?.objTrainingSponserConfig?.[selectedTrainingCat?.key]?.lstSponser);
          if (objSystemInfo?.jsnSystemConfig?.objTrainingSponserConfig?.[selectedTrainingCat?.key]?.strVedioTitle) {
            fieldTraining.strVedioTitle?.setValue(objSystemInfo?.jsnSystemConfig?.objTrainingSponserConfig?.[selectedTrainingCat?.key]?.strVedioTitle);
          }
          fieldTraining.blnShowOptionInAdminAccount.setValue(objSystemInfo?.jsnSystemConfig?.objTrainingSponserConfig?.[selectedTrainingCat?.key]?.blnShowOptionInAdminAccount ? ["true"] : []);
          fieldTraining.lstCountryTraining.setValue(objSystemInfo?.jsnSystemConfig?.objTrainingSponserConfig?.[selectedTrainingCat?.key]?.lstCountryTrainingCode ||  []);
          fieldTraining.lstContryTrainingReqVideo.setValue(objSystemInfo?.jsnSystemConfig?.objTrainingSponserConfig?.[selectedTrainingCat?.key]?.lstContryTrainingReqVideoCode ||  []);

          setStateInfo({
            ...stateInfo,
            selectedTrainingInfo: { key: selectedTrainingCat?.key, value: selectedTrainingCat?.value },
            lstVideo: objSystemInfo?.jsnSystemConfig?.objTrainingSponserConfig?.[selectedTrainingCat?.key].lstVideo || [],
            blnOpenModalSponser: true,
          });
        } catch (error) {}
      },
    handleCloseSponserModal:
      ({ fieldTraining, stateInfo, setStateInfo }) =>
      () => {
        try {
          fieldTraining.resetField();
          setStateInfo({
            ...stateInfo,
            selectedTrainingInfo: null,
            blnOpenModalSponser: false,
          });
        } catch (error) {}
      },
    handleSubmitSponserModal:
      ({ fieldTraining, uploaderVideo }) =>
      async () => {
        try {
          if (!fieldTraining.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          if (!stateInfo?.systemInfo?.jsnSystemConfig) {
            stateInfo.systemInfo.jsnSystemConfig = { ...stateInfo?.systemInfo?.jsnSystemConfig };
          }
          if (!stateInfo?.systemInfo?.jsnSystemConfig?.objTrainingSponserConfig) {
            stateInfo.systemInfo.jsnSystemConfig.objTrainingSponserConfig = { ...stateInfo?.systemInfo?.jsnSystemConfig?.objTrainingSponserConfig };
          }
          if (
            Array.isArray(stateInfo.systemInfo.jsnSystemConfig.objTrainingSponserConfig[stateInfo?.selectedTrainingInfo?.key]) ||
            !stateInfo.systemInfo.jsnSystemConfig.objTrainingSponserConfig[stateInfo?.selectedTrainingInfo?.key] ||
            typeof stateInfo.systemInfo.jsnSystemConfig.objTrainingSponserConfig[stateInfo?.selectedTrainingInfo?.key] !== "object"
          ) {
            stateInfo.systemInfo.jsnSystemConfig.objTrainingSponserConfig[stateInfo?.selectedTrainingInfo?.key] = {
              lstSponser: [],
              strVedioTitle: "",
              lstVideo: [],
            };
          }

          stateInfo.systemInfo.jsnSystemConfig.objTrainingSponserConfig[stateInfo?.selectedTrainingInfo?.key].lstSponser = fieldTraining?.lstSponser
            ?.getValue()
            ?.map((item) => Number(item?.key));
          stateInfo.systemInfo.jsnSystemConfig.objTrainingSponserConfig[stateInfo?.selectedTrainingInfo?.key].strVedioTitle = fieldTraining?.strVedioTitle?.getValue();
          if (stateInfo.lstVideo?.length) {
            const resultUploadVideo = await uploaderVideo?.uploadHandler("trainingsVideos");
            if (!resultUploadVideo) {
              setIsLoading(false);
              return;
            }
          }
          stateInfo.systemInfo.jsnSystemConfig.objTrainingSponserConfig[stateInfo?.selectedTrainingInfo?.key].lstVideo = stateInfo.lstVideo;

          stateInfo.systemInfo.jsnSystemConfig.objTrainingSponserConfig[stateInfo?.selectedTrainingInfo?.key].blnShowOptionInAdminAccount = !fieldTraining?.blnShowOptionInAdminAccount?.getValue()?.length ? false : true;
          stateInfo.systemInfo.jsnSystemConfig.objTrainingSponserConfig[stateInfo?.selectedTrainingInfo?.key].lstCountryTrainingCode = fieldTraining?.lstCountryTraining?.getValue()?.map(item=> item?.key);
          stateInfo.systemInfo.jsnSystemConfig.objTrainingSponserConfig[stateInfo?.selectedTrainingInfo?.key].lstContryTrainingReqVideoCode = fieldTraining?.lstContryTrainingReqVideo?.getValue()?.map(item=> item?.key);

          const result = await tblSystem(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: { jsnSystemConfig: stateInfo.systemInfo.jsnSystemConfig, dtmUpdatedDate: new Date().toUTCString() },
            objectCondition: {
              bigSystemID: bigSystemID,
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          fieldTraining.resetField();
          setStateInfo({
            ...stateInfo,
            selectedTrainingInfo: null,
            blnOpenModalSponser: false,
          });
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "handleSubmitSponserModal-ERROR");
          setIsLoading(false);
        }
      },
    handleSubmit:
      ({ fields, fieldsContact }) =>
      async () => {
        try {
          if(fields?.blnIsPaid?.getValue()?.key !== "paid" && (!fields?.strBankInfo?.getValue() || !fields?.strBankInfo?.getValue()?.arb || !fields?.strBankInfo?.getValue()?.eng)) {
            fields?.strBankInfo?.setValue({
              eng: fields?.strBankInfo?.getValue()?.eng || " ",
              arb: fields?.strBankInfo?.getValue()?.arb || " ",
            });
          }
          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          
          stateInfo.systemInfo.jsnSystemConfig = {
            ...stateInfo.systemInfo.jsnSystemConfig,

            lstTrainingToSupport: fields?.lstTrainingToSupport?.getValue()?.map((item) => item?.key),
            blnIsConfiremSponer: fields?.blnIsConfiremSponer?.getValue()?.length ? true : false,
            blnIsPaid: fields?.blnIsPaid?.getValue()?.key === "paid" ? true : false,
            blnIsContact: fields?.blnIsPaid?.getValue()?.key === "contact" ? true : false,

            lstContrySponserCode: (fields.lstContrySponser.getValue() || [])?.map(item=>item?.key),
            lstContryResearcherCode: (fields.lstContryResearcher.getValue() || [])?.map(item=>item?.key),
            lstContryActivityCode: (fields.lstContryActivity.getValue() || [])?.map(item=>item?.key),

            lstContryDetailsAccountCode: (fields.lstContryDetailsAccount.getValue() || [])?.map(item=>item?.key),

            lstContryResearcherReqVideoCode: (fields.lstContryResearcherReqVideo.getValue() || [])?.map(item=>item?.key),


            lstContryRegisterCode: (fields.lstContryRegister.getValue() || [])?.map(item=>item?.key),
            lstContryDetailsRegisterCode: (fields.lstContryDetailsRegister.getValue() || [])?.map(item=>item?.key),
            strDefaultContryRegisterCode: fields.strDefaultContryRegister?.getValue()?.key || "",
          
            strDetailsAAiotRegister: fields.strDetailsAAiotRegister?.getValue() ||"",
            strDetailsAAiotDefaultContryRegister: fields.strDetailsAAiotDefaultContryRegister?.getValue() ||"",
            strDetailsAAiotRegisterLink: fields.strDetailsAAiotRegisterLink?.getValue() ||"",
            strDetailsAAiotAccount: fields.strDetailsAAiotAccount?.getValue() ||"",
            strDetailsAAiotDefaultContryAccount: fields.strDetailsAAiotDefaultContryAccount?.getValue() ||"",
            strDetailsAAiotAccountLink: fields.strDetailsAAiotAccountLink?.getValue() ||"",
          };
          if(fields?.blnIsPaid?.getValue()?.key === "paid"){
            stateInfo.systemInfo.jsnSystemConfig.strBankInfo= fields?.strBankInfo?.getValue();
          }

          stateInfo.systemInfo.jsnContact = {
            ...(stateInfo?.systemInfo?.jsnContact || {}),
            strPhoneNumber: fieldsContact.strPhoneNumber?.getValue(),
            strEmail: fieldsContact.strEmail?.getValue(),
            strSupportEmail: fieldsContact.strSupportEmail?.getValue(),
            strSupportPhone: fieldsContact.strSupportPhone?.getValue(),
            strXLink: fieldsContact.strXLink?.getValue(),
            strFacebookLink: fieldsContact.strFacebookLink?.getValue(),
            strInstgramLink: fieldsContact.strInstgramLink?.getValue(),
            strLinkedInLink: fieldsContact.strLinkedInLink?.getValue(),
            strAddress: fieldsContact.strAddress?.getValue(),
            strFormEmail: fieldsContact.strFormEmail?.getValue(),
          };
          const result = await tblSystem(
            strPageInfo,
            appState,
            "aaiotAppUpdate",

            {
              objectInput: { ...stateInfo.systemInfo, dtmUpdatedDate: new Date().toUTCString() },
              objectCondition: {
                bigSystemID: bigSystemID,
                blnIsActive: true,
                blnIsDeleted: false,
              },
            }
          );
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          setStateInfo({ ...stateInfo, blnIsInitDone: true });
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      },

    // handleSubmitTrainingModal:
    //   ({ fieldTraining }) =>
    //   async () => {
    //     try {
    //       if (!fieldTraining.isFormValid()) {
    //         Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
    //         return;
    //       }
    //       if (isLoading) {
    //         Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
    //         return;
    //       }
    //       setIsLoading(true);

    //       stateInfo.systemInfo.jsnSystemConfig.objTrainingInfo[stateInfo?.selectedTrainingInfo?.key] = fieldTraining?.lstSponser?.getValue()?.map((item) => item?.key);

    //       const result = await tblSystem(
    //         strPageInfo,
    //         appState,
    //         "aaiotAppUpdate",

    //         {
    //           objectInput: { ...stateInfo.systemInfo, dtmUpdatedDate: new Date().toUTCString() },
    //           objectCondition: {
    //             bigSystemID: bigSystemID,
    //             blnIsActive: true,
    //             blnIsDeleted: false,
    //           },
    //         }
    //       );
    //       if (!result?.blnIsRequestSuccessful) {
    //         appFunctions.logMessage(result?.response, "");
    //         Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning");
    //         setIsLoading(false);
    //         return;
    //       }
    //       setIsLoading(false);
    //       fieldTraining.resetField();
    //       setStateInfo({
    //         ...stateInfo,
    //         selectedTrainingInfo: null,
    //         blnOpenModalSponser: false,
    //       });
    //       Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
    //     } catch (error) {
    //       appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
    //       setIsLoading(false);
    //     }
    //   },

    addNewSponsorCatalogueToDB:
      ({ fldCatalogue }) =>
      async () => {
        try {
          if (!fldCatalogue.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(false);

          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region add data DB
          const bigTrackID = appFunctions.generateID(10);

          if (!stateInfo?.systemInfo?.jsnSponsorCatalogue?.list || !Array.isArray(stateInfo?.systemInfo?.jsnSponsorCatalogue?.list)) {
            if (!stateInfo?.systemInfo?.jsnSponsorCatalogue) {
              stateInfo.systemInfo.jsnSponsorCatalogue = {};
            }
            stateInfo.systemInfo.jsnSponsorCatalogue.list = { ...stateInfo.systemInfo.jsnSponsorCatalogue.list };
            stateInfo.systemInfo.jsnSponsorCatalogue.list = [];
          }

          stateInfo.systemInfo.jsnSponsorCatalogue.list.push({
            id: bigTrackID,
            strSponsorName: fldCatalogue.strSponsorName.getValue(),
            strSponsorEmail: fldCatalogue.strSponsorEmail.getValue(),
            bigWorkSector: fldCatalogue.bigWorkSector.getValue()?.key,
            strURL: fldCatalogue.strURL.getValue(),
            dtmCreatedDate: new Date().toUTCString(),
            lstTrainingType: [],
          });

          const result = await tblSystem(strPageInfo, appState, "aaiotAppUpdate", {
            objectCondition: {
              bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || aaiot_System_ID,
            },
            objectInput: {
              jsnSponsorCatalogue: stateInfo.systemInfo.jsnSponsorCatalogue,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Save_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update cache

          stateInfo.blnOpenAddSponsorCatalogueModal = false;

          setStateInfo({ ...stateInfo });
          setIsLoading(false);
          Alert.viewAlert(dictionary.shared.alertFetchNote.added_Successfully?.[lang], "success");
          //#endregion
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "ERROR-addNewSponsorCatalogueToDB");
        }
      },
  };
  return handler;
};
