import { useEffect } from "react";
import * as appVariables from "../appVariables";
import * as appFunctions from "../appFunctions";
import { ClientFunction } from "@cbmisorg/client-app";
import { generateQueries, objTableAttributes } from "../appQuery/GenerateQuery";
import mngAnnouncementCtrl from "../../components/stakeholders/admin/mngAnnouncement/mngAnnouncement.controller";
import { useApp } from "../../../client/configuration/contextapi/context";

const strPageInfo = "@src/appHelper/hooks/useCheckSystemInfo.js";
const tblSystem = generateQueries("tblSystem");
const tblStatistic = generateQueries("tblStatistic");

export default function useCheckSystemInfo(props) {
  const { appState } = useApp();

  useEffect(() => {
    if (!props?.notHook) {
      checkHookValidity();
    }
  }, []);

  async function checkHookValidity() {
    try {
      let intLastChecked = ClientFunction.getDifferenceBetweenTwoDate(appState.clientInfo.dtmLastCheckedSystemInfo, new Date(), "hour");
      const checked = props?.isGetCount ? intLastChecked < appVariables.System_Info_Count_HoursToCheck : intLastChecked < appVariables.System_Info_HoursToCheck;
      if (checked && !Number.isNaN(Number(intLastChecked)) && Object.keys(appState?.clientInfo?.objSystemInfo).length > 0) {
        return appState?.clientInfo?.objSystemInfo;
      }

      await getNewSystemInfo();
    } catch (error) {
      appFunctions.logMessage(strPageInfo, "checkHookValidity-ERROR", error);
    }
  }

  async function getNewSystemInfo(appDispatch = null) {
    try {
      const result = await tblSystem(strPageInfo, appState, "aaiotAppSystemGetOneFK", {
        objectCondition: {
          bigSystemID: appState?.userInfo?.bigSystemID || appVariables.aaiot_System_ID,
          bigAppID: appState?.userInfo?.bigAppID || appVariables.App_ID,
        },
        arrAttributes: props?.isGetCount
          ? objTableAttributes?.tblSystem?.full
          : ["bigSystemID", "jsnSystemConfig", "blnIsActive", "jsnSystemConfig", "jsnContact", "jsnSponsorCatalogue"],
      });
      if (!result?.blnIsRequestSuccessful) {
        return;
      }

      let objSystemInfo = {};
      if (props?.isGetCount) {
        const resultStatistic = await tblStatistic(strPageInfo, appState, "aaiotAppFindOne", {
          objectCondition: {
            bigSystemID: appState?.userInfo?.bigSystemID,
          },
          arrAttributes: objTableAttributes.tblStatistic.full,
        });
        if (!resultStatistic?.blnIsRequestSuccessful) {
          return;
        }

        let { lstMeeting, lstNotification } = await mngAnnouncementCtrl({ appDispatch, appState }).getCounterAnnouncementToDisplay(result?.response?.jsnLstNotification);

        objSystemInfo = {
          ...resultStatistic.response,
          intCountAllStudentsRole: resultStatistic.response?.intCountJoinEntrepreneur + resultStatistic.response?.intCountJoinManager,
          intCountAnnouncement: lstNotification?.length,
          intCountMeeting: lstMeeting?.length,
        };
      }

      objSystemInfo = { ...objSystemInfo, ...result?.response };

      if (JSON.stringify(appState?.clientInfo?.objSystemInfo) !== JSON.stringify(objSystemInfo)) {
        appState.clientInfo.objSystemInfo = objSystemInfo;
        appState.clientInfo.dtmLastCheckedSystemInfo = ClientFunction.getCurrentDate();
        appFunctions.setAppLocalStorage(appState);

        if (appDispatch) {
          appDispatch();
        }
      } else {
        changeLastCheckedDateNoRender();
      }
      return objSystemInfo;
    } catch (error) {
      appFunctions.logMessage(strPageInfo, "getNewSystemInfo-ERROR", error);
    }
  }

  function changeLastCheckedDateNoRender() {
    try {
      appState.clientInfo.dtmLastCheckedSystemInfo = ClientFunction.getCurrentDate();
      appFunctions.setAppLocalStorage(appState);
    } catch {}
  }

  return { getNewSystemInfo };
}
