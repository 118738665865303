import React, { useEffect, useState } from "react";
import { ClassesBuilder } from "@cbmisorg/styles";
import { Button, Container, Grid, Typography, Modal, ModalHeader, ModalBody } from "@cbmisorg/material";

import { useApp } from "../../../../../../configuration/contextapi/context";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";

import { CtrlRegActivityGuest } from "./RegActivityGuest.controller";
import { objStyle } from "./RegActivityGuest.style";

import { App_info_Color, App_Second_Color } from "../../../../../../util/appStyle";
import { workSectorID } from "../../../../../../configuration/appVariables";
import WorkSectorOption from "./component/WorkSectorOption.view";
import SubjectOption from "./component/SubjectOption.view";
import { modalOverFllow } from "../../../../../../util/appFunctions";

const labels = dictionary?.components?.stakeholders?.member?.account?.regActivityGuest;

function RegActivityGuestView({ isLoading, setIsLoading, activityInfo, closeModal, parentState, setParentState }) {
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle, { lang });

  const [state, setState] = useState({
    blnIsInitDone: false,
    objActivityInfo: {},
    lstCurentSubject: [],
    lstDefaultActivitySubject: [],
    subjectSelected: null,
    blnOpenModal: false,
  });

  const fields = FormData({
    bigWorkSector: {
      control: "list",
      blnLanguage: false,
      validations: { required: true },
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },
    strPlaceName: {
      control: "text",
      blnLanguage: false,
    },
    lstSubject: {
      control: "radio",
      blnLanguage: false,
      validations: {},
    },
    lstTargeted: {
      control: "list",
      blnLanguage: false,
      validations: {
        required: true,
      },
    },
  });

  const handlers = CtrlRegActivityGuest({ appState, appDispatch, isLoading, setIsLoading });
  const handelWorkSectorChange = handlers.handelWorkSectorChange({
    fields,
    state,
    setState,
    WorkSectorOptionComponent: WorkSectorOption,
    SubjectOptionComponent: SubjectOption,
    blnTargetedChange: false,
    parentState,
    activityInfo,
  });
  const handelTargetedChange = handlers.handelWorkSectorChange({
    fields,
    state,
    setState,
    WorkSectorOptionComponent: WorkSectorOption,
    SubjectOptionComponent: SubjectOption,
    blnTargetedChange: true,
    parentState,
    activityInfo,
  });
  const onChangeSubject = handlers.onChangeSubject({ fields, state });

  function handleSubmit() {
    handlers.addGuest({ state, setState, fields, closeModal, activityInfo, parentState, setParentState });
  }

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      handlers.initData({
        activityInfo,
        state,
        setState,
        parentState,
        fields,
        WorkSectorOptionComponent: WorkSectorOption,
        SubjectOptionComponent: SubjectOption,
        closeModal,
      });
    }
    return () => {
      modalOverFllow();
    };
  }, []);

  return (
    <React.Fragment>
      <Container py-0>
        <Grid container spacing={2}>
          <Grid item className={classes?.subtitle} p-3>
            <Typography as={lang === "arb" ? "subtitle1" : "subtitle2"} className={classes?.subtitleLabel}>
              {labels?.registrationInfo?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs={12} p-0 />
          <Grid item xs="12" md={fields.bigWorkSector?.getValue() ? 6 : 12}>
            <FormField
              objHandler={fields.bigWorkSector}
              icon="Work"
              mode={"classic"}
              color={App_Second_Color}
              className={classes?.field}
              onChange={handelWorkSectorChange}
            />
          </Grid>
          <Grid item xs={fields.bigWorkSector?.getValue() ? 12 : "0"} md={fields.bigWorkSector?.getValue() ? 6 : "0"}>
            <FormField
              objHandler={fields.lstTargeted}
              icon="people"
              mode={"classic"}
              color={App_Second_Color}
              className={classes?.field}
              onChange={handelTargetedChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField objHandler={fields.strPlaceName} icon="business" mode={"classic"} color={App_info_Color} className={classes?.field} />
          </Grid>

          {state.lstCurentSubject?.length && fields.lstTargeted?.getValue()?.key && (
            <React.Fragment>
              <Grid item className={classes?.subtitle} p-3>
                <Typography as={lang === "arb" ? "subtitle1" : "subtitle2"} className={classes?.subtitleLabel}>
                  {labels?.detailsInfo?.[lang]}
                </Typography>
              </Grid>
              <Grid item xs="12">
                {state?.objActivityInfo?.type?.value?.["eng"] !== "none" ? (
                  <FormField objHandler={fields.lstSubject} color={App_Second_Color} onChange={onChangeSubject} />
                ) : (
                  Object?.keys(state.lstCurentSubject)?.map((element, index) => {
                    return (
                      <SubjectOption
                        key={index}
                        element={state.lstCurentSubject?.[element]?.subjectValue}
                        classes={classes}
                        blnLst={true}
                        lang={lang}
                        state={state}
                        setState={setState}
                      />
                    );
                  })
                )}
              </Grid>
            </React.Fragment>
          )}
        </Grid>

        <Grid container justifyContent={"center"} spacing={2}>
          <Grid item xs="12" py-3>
            <Button
              py-0
              px-10
              label={
                !(Array.isArray(parentState.lstUserRegActivity) && parentState.lstUserRegActivity?.length)
                  ? labels?.btn?.[lang]
                  : dictionary?.shared?.buttons?.save?.[lang]
              }
              mode="outlined"
              color={!fields?.lstSubject?.getValue() ? "#999" : App_info_Color}
              onClick={handleSubmit}
              className={classes?.btn}
              size="sm"
              disabled={!fields?.lstSubject?.getValue()}
            />
          </Grid>
        </Grid>
      </Container>

      <Modal
        open={state?.blnOpenModal}
        eventClose={() => {
          modalOverFllow();
          setState({ ...state, blnOpenModal: false, subjectSelected: null });
        }}
        className={classes?.modal}
      >
        <ModalHeader className={classes?.modalHeader}></ModalHeader>
        <ModalBody className={classes?.modalBody}>
          <Grid container justifyContent="flex-start" alignItems="flex-start">
            {/* <Grid item xs="11">
              <img src={state?.subjectSelected?.detail?.value?.[lang]} alt={state?.subjectSelected?.value?.[lang]} className={classes?.imgModal} />
            </Grid> */}

            <Grid item xs="12" md="8">
              <img src={state?.subjectSelected?.detail?.table?.value?.[lang]} alt={state?.subjectSelected?.value?.[lang]} className={classes?.imgModal} />
            </Grid>
            <Grid item xs="12" md="4">
              <img src={state?.subjectSelected?.detail?.speakers?.value?.[lang]} alt={state?.subjectSelected?.value?.[lang]} className={classes?.imgModal} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default RegActivityGuestView;
