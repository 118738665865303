import { ClassesBuilder } from "@cbmisorg/styles";
import React, { useEffect, useState } from "react";
import useCheckSystemInfo from "../../../util/hooks/useCheckSystemInfo";
import { useApp } from "../../../configuration/contextapi/context";
import { objStyle } from "./Media.style";
import { useParams } from "@cbmisorg/router";
import { logMessage } from "../../../util/appFunctions";
import { Alert, Container, Grid, Loader } from "@cbmisorg/material";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { alertStyle, App_info_Color } from "../../../util/appStyle";
import ShredHeader from "../../shared/shredHeader";
import NoResultImg from "../../../../old/components/sharedUi/noResultImg/NoResultImg";
import { App_Server_Url_DownloadFiles } from "../../../configuration/appVariables";

const strPageInfo = "@src/client/omponent/ublic/edia/edia.view.js";

function MediaView() {
  const { appState } = useApp();
  const { getNewSystemInfo } = useCheckSystemInfo({ appState });

  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    selectedVideoInfo: null,
  });
  const lang = appState?.clientInfo?.strLanguage;
  const classes = ClassesBuilder(objStyle, { lang });
  const { strUrlVedio } = useParams();

  const getSystemInfo = async () => {
    try {
      if (isLoading) {
        Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
        return;
      }

      setIsLoading(true);
      const result = await getNewSystemInfo(null, true);

      stateInfo.blnIsInitDone = true;
      if (!Array.isArray(result?.jsnSystemConfig?.lstAdvVideos) || !result?.jsnSystemConfig?.lstAdvVideos?.length) {
        stateInfo.selectedVideoInfo = null;
      } else {
        stateInfo.selectedVideoInfo = result?.jsnSystemConfig?.lstAdvVideos?.filter((video) => video?.strVedioUrl === strUrlVedio)[0];

        if (!stateInfo.selectedVideoInfo) {
          stateInfo.selectedVideoInfo = null;
        }
      }
      setStateInfo({ ...stateInfo });
      setIsLoading(false);
    } catch (error) {
      logMessage(strPageInfo, "getNewSystemInfo-ERROR", error);
    }
  };

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      getSystemInfo();
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader
        lang={lang}
        title={
          stateInfo?.selectedVideoInfo?.strVedioTitle?.[lang] || stateInfo?.selectedVideoInfo?.strVedioTitle?.["arb"] || stateInfo?.selectedVideoInfo?.strVedioTitle?.["eng"] || ""
        }
        blnBack={false}
      />
      <Loader backdrop={isLoading} color={App_info_Color} />
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer}>
          <Grid container justifyContent={"center"} alignSelf="center">
            <Grid item xs="12" md="11" lg="10">
              {stateInfo?.selectedVideoInfo?.lstVideos?.length ? (
                stateInfo?.selectedVideoInfo?.lstVideos?.map((video, index) => (
                  <Grid item xs="12" key={index}>
                    <video controls className="videoTraining">
                      <source src={App_Server_Url_DownloadFiles + video?.path} type="video/mp4" />
                    </video>
                  </Grid>
                ))
              ) : (
                <Grid item xs="12">
                  <NoResultImg />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default MediaView;
