import { Alert } from "@cbmisorg/material";
import * as appVariables from "../../../../../../configuration/appVariables";
import * as appFunctions from "../../../../../../util/appFunctions";
import { appLangDirection, dictionary } from "../../../../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../../../../graphql/schema/GenerateQuery";
import { alertStyle } from "../../../../../../util/appStyle";
const userApi = generateQueries("tblUser");
const statisticApi = generateQueries("tblStatistic");

const strPageInfo = "@src/client/component/shared/account/components/researcherSetterModal/researcherSetterModal.controller.js";

export default function researcherSetterModalCtrl({ appState, appDispatch, isLoading, setIsLoading }) {
  const lang = appState?.clientInfo?.strLanguage;
  const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

  const handler = {
    openModal:
      ({ state, setState, fields }) =>
      async () => {
        try {
          fields.controller.resetForm();
          appFunctions.modalOverFllow(true);

          let lstCountryOption = [];
          let lstDegreeOption = [];
          let lstEmploymentOption = [];
          let lstMajorOption = [];
          let lstGenderOption = [];

          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstCountryOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.degree || {}).forEach(([key, value]) => {
            if (String(value?.id) === String(objCat?.Root?.degree?.master?.id) || String(value?.id) === String(objCat?.Root?.degree?.PHD?.id)) {
              lstDegreeOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.employment || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value" && String(value?.id) !== String(objCat?.Root?.employment?.student?.id)) {
              lstEmploymentOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.value });
            }
          });

          fields.bigMajorID.setOptions([...lstMajorOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields.bigDegreeID.setOptions([...lstDegreeOption]);
          fields.bigGender.setOptions(lstGenderOption);
          fields.bigEmploymentID.setOptions(lstEmploymentOption);

          state.blnIsNew = !appState?.userInfo?.blnIsStudent;
          if (appState?.userInfo?.jsnUserInfo?.strFullName) {
            fields.strFullName.setValue(appState?.userInfo?.jsnUserInfo?.strFullName?.[lang] || appState?.userInfo?.jsnUserInfo?.strFullName);
          }
          if (appState?.userInfo?.jsnUserInfo?.strPlaceName) {
            fields.strPlaceName.setValue(appState?.userInfo?.jsnUserInfo?.strPlaceName);
          }
          if (appState?.userInfo?.jsnUserInfo?.strPositionTitle) {
            fields.strPositionTitle.setValue(appState?.userInfo?.jsnUserInfo?.strPositionTitle);
          }
          if (appState?.userInfo?.jsnUserInfo?.strMajor) {
            fields.bigMajorID.setValue(appState?.userInfo?.jsnUserInfo?.strMajor);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigEmploymentID) {
            fields.bigEmploymentID.setValue(appState?.userInfo?.jsnUserInfo?.bigEmploymentID);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigDegreeID) {
            fields.bigDegreeID.setValue(appState?.userInfo?.jsnUserInfo?.bigDegreeID);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigGender) {
            fields.bigGender.setValue(appState?.userInfo?.jsnUserInfo?.bigGender);
          }
          if (appState?.userInfo?.jsnUserInfo?.strPlaceName) {
            fields.strPlaceName.setValue(appState?.userInfo?.jsnUserInfo?.strPlaceName);
          }
          if (appState?.userInfo?.jsnUserInfo?.strPositionTitle) {
            fields.strPositionTitle.setValue(appState?.userInfo?.jsnUserInfo?.strPositionTitle);
          }

          state.lstFirstProofSection = Array.isArray(appState?.userInfo?.jsnUserInfo?.lstFirstProofSection) ? appState?.userInfo?.jsnUserInfo?.lstFirstProofSection : [];

          state.blnOpenSetterModal = true;
          setState({ ...state });
        } catch (error) {
          appFunctions.logError(strPageInfo, "openModal-ERROR", error);
        }
      },

    onChangeCountry:
      ({ fields }) =>
      (event) => {
        try {
          const bigCountryID = event?.target?.id;

          // const objCat = appFunctions.getCategoryCache();

          let lstUniversityOption = [];
          Object.entries(objCat?.Root?.country?.[appFunctions.appCategoryGetKey(objCat?.Root?.country, bigCountryID)]?.university || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstUniversityOption.push({ key: value?.id, value: value?.value });
            }
          });
          lstUniversityOption.push({ key: "other", value: dictionary?.shared?.buttons?.otherBtn });
        } catch (error) {
          appFunctions.logError(strPageInfo, "onChangeCountry-ERROR", error);
        }
      },

    updateResearcherInfo:
      ({ state, setState, fields, uploaderFirstProofSection }) =>
      async () => {
        try {
          if (!fields?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }

          setIsLoading(true);
          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update user
          const resultUser = await userApi(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectCondition: {
              bigUserID: appState?.userInfo?.bigUserID,
            },
            objectInput: {
              jsnUserInfo: {
                ...appState?.userInfo?.jsnUserInfo,
                strFullName: fields?.strFullName?.getValue(),
                strPlaceName: fields?.strPlaceName?.getValue(),
                strPositionTitle: fields?.strPositionTitle?.getValue(),
                strMajor: fields?.bigMajorID?.getValue()?.key,
                bigEmploymentID: fields?.bigEmploymentID?.getValue()?.key,
                bigDegreeID: fields?.bigDegreeID?.getValue()?.key,
                bigGender: fields?.bigGender?.getValue()?.key,
              },
              blnIsResearcher: true,
              dtmUpdatedDate: new Date(),
            },
          });

          if (!resultUser?.blnIsRequestSuccessful) {
            setIsLoading(false);
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Update_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }

          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region statistics update
          if (!appState.userInfo.blnIsResearcher) {
            let arrAttributes = [];
            arrAttributes.push("intCountJoinResearcher");
            arrAttributes.push("intCountJoinResearcherAll");
            if (arrAttributes?.length) {
              await statisticApi(strPageInfo, appState, "aaiotAppIncDecInt", {
                objectCondition: {
                  bigSystemID: appVariables.aaiot_System_ID,
                },
                arrAttributes: arrAttributes,
              });
            }
          }
          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          appState.userInfo.blnIsResearcher = true;

          appState.userInfo.jsnUserInfo = {
            ...appState?.userInfo?.jsnUserInfo,
            strFullName: fields?.strFullName?.getValue(),
            strPlaceName: fields?.strPlaceName?.getValue(),
            strPositionTitle: fields?.strPositionTitle?.getValue(),
            strMajor: fields?.bigMajorID?.getValue()?.key,
            bigEmploymentID: fields?.bigEmploymentID?.getValue()?.key,
            bigDegreeID: fields?.bigDegreeID?.getValue()?.key,
            bigGender: fields?.bigGender?.getValue()?.key,
          };

          state.blnOpenSetterModal = false;
          appFunctions.modalOverFllow();
          appDispatch();
          setState({ ...state });
          setIsLoading(false);
          Alert.viewAlert(dictionary.shared.alertFetchNote.updated_innfo_Successfully?.[lang], "success", appLangDirection?.[lang], alertStyle);
        } catch (error) {
          appFunctions.logError(strPageInfo, "updateResearcherInfo-ERROR", error);
          setIsLoading(false);
        }
      },

    objCat: objCat,
  };
  return handler;
}
