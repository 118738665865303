import React, { useEffect, useState } from "react";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionTab,
  Container,
  Grid,
  Icon,
  Line,
  Loader,
  MenuItem,
  Typography,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  useNavigate,
} from "@cbmisorg/client-app";
import NoResultImg from "../../../sharedUi/noResultImg/NoResultImg";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import { App_Dark_Color, App_Primary_Color, App_Text_Color } from "../../../../appHelper/appColor";
import registeredActivitiesCtrl from "./RegisteredActivities.controller";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import { workSectorIDLabel } from "../../../../appHelper/appVariables";
import excelIcon from "../../../../assets/images/excel-icon.png";
import { useApp } from "../../../../../client/configuration/contextapi/context";
import * as appFunctions from "../../../../appHelper/appFunctions";
import UploaderReader from "../../../sharedUi/uploader/UploadRead";

function RegisteredActivitiesView() {
  useCheckSystemInfo();
  useCheckCat();

  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const labels = dictionary?.components?.registeredActivities;
  const objCat = appFunctions.getCategoryCache();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsFetchDone: false,
    lstActivities: [],
    lstActivitiesRead: [],
    indexSelected: -1,
    openModal: false,
  });

  const fields = FormData({
    lstActivityType: {
      strControl: "list",
      lstOption: [],
    },
    lstSubject: {
      strControl: "list",
      lstOption: [],
    },
  });

  const handlers = registeredActivitiesCtrl({ appState, appDispatch });
  const initData = handlers.initData({ isLoading, setIsLoading, setState, state, fields });
  const exportExcelFile = handlers.exportExcelFile({ state });
  const onChangeActivety = handlers.onChangeActivety({ state, setState, fields });
  const onChangeSubject = handlers.onChangeSubject({ state, setState, fields });
  const onSubmitConfirmation = handlers.onSubmitConfirmation({ state, setState, isLoading, setIsLoading });
  const exportExcelFileFirstStudent = handlers?.exportExcelFileFirstStudent({ state, setState, navigate });

  useEffect(() => {
    if (!state.blnIsFetchDone) {
      initData();
    }
  }, []);

  return (
    <>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false} />
      <Container py-3>
        {!isLoading ? null : <Loader color={App_Dark_Color} backdrop={true} />}

        {!state.lstActivities?.length ? (
          <Grid container justify={"center"}>
            <NoResultImg xs={8} md={6} lg={6} />
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container justify={"space-between"}>
              <Grid item>
                <Typography as="body1">
                  {labels?.number?.[lang]}: {state?.lstActivitiesRead?.length}
                </Typography>
              </Grid>
              <Grid item xs="12" md="auto">
                <Grid container justify={"space-between"}>
                  <Grid item xs="0" md="auto" justify={"end"} px-3>
                    <span onClick={exportExcelFileFirstStudent} style={{ display: "flex" }}>
                      <Typography as="caption" px-2 py-0>
                        {labels?.certificateBtn?.[lang]}
                      </Typography>
                      <img src={excelIcon} alt="" width={"25px"} style={{ borderRadius: "4px" }} />
                    </span>
                  </Grid>
                  <Grid item xs="auto" md="0" justify={"end"} px-3>
                    <span onClick={exportExcelFileFirstStudent} style={{ display: "flex" }}>
                      <img src={excelIcon} alt="" width={"25px"} style={{ borderRadius: "4px" }} />
                      <Typography as="caption" px-2 py-0>
                        {labels?.certificateBtn?.[lang]}
                      </Typography>
                    </span>
                  </Grid>
                  <Grid item justify={"end"} mt-1 px-1>
                    <span onClick={exportExcelFile}>
                      <img src={excelIcon} alt="" width={"25px"} style={{ borderRadius: "4px" }} />
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Line />
            <Grid container justify={"space-between"}>
              <Grid item xs="12" md="6">
                <FormField objHandler={fields?.lstActivityType} dir={lang === "arb" ? "rtl" : "ltr"} onChange={onChangeActivety} />
              </Grid>
              <Grid item xs="12" md="6">
                <FormField objHandler={fields?.lstSubject} dir={lang === "arb" ? "rtl" : "ltr"} onChange={onChangeSubject} />
              </Grid>
            </Grid>
            <Grid container justify={"center"}>
              {[...(state.lstActivitiesRead || [])].reverse()?.map((activity, intIndex) => {
                let strActivityGuestKey = appFunctions.appCategoryGetKey(objCat?.Root?.activity, activity?.bigActivityType);
                let lstSubjectUser = Array.isArray(activity?.jsnGuestInfo?.objSubject) ? activity?.jsnGuestInfo?.objSubject : [];
                return (
                  <Grid item xs="12" key={activity?.bigActivityGuestID + intIndex}>
                    <MenuItem
                      sx={{
                        width: "100%",
                        border: `1px solid ${App_Text_Color}`,
                        color: App_Primary_Color,
                        display: "block",
                        background: activity?.jsnGuestInfo?.blnIsConfirmInvitation
                          ? "#DDF7DF"
                          : activity?.jsnGuestInfo?.blnIsConfirmFirstSections
                          ? "#d0fcfc"
                          : activity?.jsnGuestInfo?.blnIsFirstSections
                          ? "#FFE4E1"
                          : "",
                      }}
                      outlined
                    >
                      <div style={{ display: "block", width: "98%" }}>
                        <Grid container>
                          <Grid item xs="8" sm="9" md="10">
                            <Grid container>
                              <Grid item xs="12">
                                <Typography as="subtitle2" className="inline-ellipsis">
                                  {activity?.jsnGuestInfo?.strFullName?.[lang] || activity?.jsnGuestInfo?.strFullName}
                                </Typography>
                              </Grid>
                              <Grid item xs="12" container>
                                <Typography as="caption" className="inline-ellipsis" color={"dark"}>
                                  {workSectorIDLabel?.[activity?.bigWorkSector]?.[lang]} -{" "}
                                  {activity?.strPlaceName || appFunctions?.checkParseObject(activity?.jsnGuestInfo?.strUniversityName)?.[lang]}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs="4" sm="3" md="2" container spacing={0} justify={"end"}>
                            <Grid item xs="12" container justify={"end"}>
                              {activity?.jsnGuestInfo?.blnIsFirstSections && (
                                <Grid item px-0>
                                  <Button
                                    mode="link"
                                    icon="school"
                                    color={App_Primary_Color}
                                    mx-0
                                    onClick={() => setState({ ...state, openModal: true, indexSelected: (state.lstActivitiesRead || [])?.length - 1 - intIndex })}
                                  />
                                </Grid>
                              )}
                              <Grid item>
                                <Button mode="link" icon="email" color={App_Primary_Color} onClick={() => window.open(`mailto:${activity?.strUserEmail}`)} />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {!lstSubjectUser?.length ? null : (
                          <>
                            <Accordion>
                              <AccordionTab>
                                <AccordionSummary p-2 sx={{ border: `1px solid ${App_Primary_Color}`, borderRadius: "0.4em" }}>
                                  <Typography as="caption" sx={{ fontWeight: "bold" }}>
                                    {labels?.userRegActivitySubject?.[lang]} ({lstSubjectUser?.length})
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails p-0>
                                  {lstSubjectUser?.map((subject, index) => {
                                    let strSubjectKey = appFunctions.appCategoryGetKey(objCat?.Root?.activity?.[strActivityGuestKey]?.subject, subject);
                                    let objSubjectInfo = objCat?.Root?.activity?.[strActivityGuestKey]?.subject?.[strSubjectKey];
                                    let strSubjectName = objSubjectInfo?.value?.[lang] || "";
                                    let strSubjectDate = new Date(objSubjectInfo?.date?.value?.eng).toLocaleDateString();
                                    let strSubjectTarget = objSubjectInfo?.targeted?.value?.[lang] || "";

                                    return (
                                      <Grid container mb-1 key={activity?.bigActivityGuestID + "act-" + activity?.bigActivityType + String(subject) + index}>
                                        {strSubjectName && strSubjectDate && (
                                          <Grid item xs="12">
                                            <Typography as="caption" color={"dark"}>
                                              -{strSubjectName} ({strSubjectDate})
                                            </Typography>
                                          </Grid>
                                        )}
                                        {strSubjectTarget && (
                                          <Grid item xs="12">
                                            <Typography as="caption" color={"dark"}>
                                              -{strSubjectTarget}
                                            </Typography>
                                          </Grid>
                                        )}

                                        <Line sx={{ width: "100%" }} mt-1 />
                                      </Grid>
                                    );
                                  })}
                                </AccordionDetails>
                              </AccordionTab>
                            </Accordion>
                          </>
                        )}
                      </div>
                    </MenuItem>
                  </Grid>
                );
              })}
            </Grid>
          </React.Fragment>
        )}
      </Container>

      <Modal size="sm" open={state?.openModal} eventClose={() => setState({ ...state, openModal: false, indexSelected: -1 })}>
        <ModalHeader>{labels?.topDepartment?.[lang]}</ModalHeader>
        <ModalBody>
          <Grid container spacing={2}>
            <Grid item xs="12">
              <Typography as="caption">
                <b>{labels?.fullName?.[lang]}: </b>
                {state?.lstActivitiesRead?.[state?.indexSelected]?.jsnGuestInfo?.strFullName?.[lang] || state?.lstActivitiesRead?.[state?.indexSelected]?.jsnGuestInfo?.strFullName}
              </Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="caption">
                <b>{labels?.strUniversityName?.[lang]}: </b>
                {appFunctions?.checkParseObject(state?.lstActivitiesRead?.[state?.indexSelected]?.jsnGuestInfo?.strUniversityName)?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="caption">
                <b>{labels?.bigAcademicYearID?.[lang]}: </b>
                {appFunctions?.checkParseObject(state?.lstActivitiesRead?.[state?.indexSelected]?.jsnGuestInfo?.strAcademicYearID)?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="caption">
                <b>{labels?.intGPA?.[lang]}: </b>
                {state?.lstActivitiesRead?.[state?.indexSelected]?.jsnGuestInfo?.strGPA} / {state?.lstActivitiesRead?.[state?.indexSelected]?.jsnGuestInfo?.strGPAof}
              </Typography>
            </Grid>
            <Grid item>
              <Typography as="caption">
                <b>{labels?.transcript?.[lang]}</b>
              </Typography>
            </Grid>
            <Grid item>
              <UploaderReader lstFiles={state?.lstActivitiesRead?.[state?.indexSelected]?.jsnGuestInfo?.lstFirstProofSection} />
            </Grid>

            {state?.lstActivitiesRead?.[state?.indexSelected]?.jsnGuestInfo?.blnIsConfirmFirstSections ? (
              <Grid item xs="12">
                <Typography as="caption" color="primary">
                  {labels?.confirmInvitation?.[lang] + " "}
                  {state?.lstActivitiesRead?.[state?.indexSelected]?.jsnGuestInfo?.blnIsConfirmInvitation
                    ? dictionary?.shared?.confirmationMsg?.yes?.[lang]
                    : dictionary?.shared?.confirmationMsg?.no?.[lang]
                    }
                </Typography>
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item xs="12">
                  <Typography as="caption" color="primary">
                    *{labels?.note?.[lang]}
                  </Typography>
                </Grid>
                <Grid item m-auto py-3>
                  <Button label={<Typography as="caption">{labels?.condrmationBtn?.[lang]}</Typography>} size="sm" onClick={onSubmitConfirmation} />
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </ModalBody>
      </Modal>
    </>
  );
}

export default RegisteredActivitiesView;
