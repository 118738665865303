import { useParams } from '@cbmisorg/client-app'
import React from 'react'
import { objTrainingToSupport } from '../../../../appHelper/appVariables'
import RouteMngRequestedJoin from '../mngRequestedJoin/RouteMngRequestedJoin'
import RouteaMnageManagers from '../manageManagers/RouteaMnageManagers'

function RoutmMangTrainingInfo() {
    const {strTrainingType}=useParams()

    if(strTrainingType === objTrainingToSupport?.fullstack?.key || strTrainingType === "startup" || strTrainingType === "researcher"){
        return (
            <RouteMngRequestedJoin blnIsStartup={strTrainingType === "startup"} blnResearcher ={strTrainingType === "researcher"}/>
        )
    }else  if(strTrainingType === objTrainingToSupport?.manager?.key){
        return (
            <RouteaMnageManagers />
        )
    }
    return null
}

export default RoutmMangTrainingInfo