import { BtnGoToTop, ClientDirection, Container, Grid } from "@cbmisorg/client-app";
import React, { useEffect } from "react";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import EventCard from "../../../sharedUi/eventCard/EventCard";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import { useApp } from "../../../../../client/configuration/contextapi/context";

function Events() {
  const { getNewSystemInfo } = useCheckSystemInfo();
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const labels = dictionary?.components?.public?.landingPage?.home?.events;

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
  }, [lang]);

  const intData = async () => {
    if (!appState?.clientInfo?.objSystemInfo || !Object.keys(appState?.clientInfo?.objSystemInfo)?.length) {
      appState.clientInfo.objSystemInfo = await getNewSystemInfo();
      appDispatch();
    }
  };

  useEffect(() => {
    intData();
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} />
      <Container key={JSON.stringify(appState?.clientInfo?.objSystemInfo?.lstActivities || [])}>
        <Grid container id="ourGoal" px-0 spacing={0} pb-xs-10 mb-xs-10>
          <Grid item xs="12" container justify="center" pb-xs-10 mb-xs-10>
            <Container>
              <Grid container spacing={3} justify="center">
                {appState?.clientInfo?.objSystemInfo?.lstActivities?.map((item, index) => {
                  return (
                    <Grid item xs="12" sm="6" lg="4" key={index}>
                      <EventCard item={item} lang={lang} />
                    </Grid>
                  );
                })}
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <BtnGoToTop className={lang === "arb" ? "arbLang" : ""} />
    </React.Fragment>
  );
}

export default Events;
